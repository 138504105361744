import React from "react";
import { useTheme } from "styled-components";

import ArticleA from "../../atoms/ArticleA";

const RecommendItem = ({
  article,
  itemIndex,
  page
}) => {
  const theme = useTheme();
  const tags = article.topics.map((topic, index) => {
    return (
      <li key={`tag-ri-${theme.page}-${index}`} className="m-tag__item">{`#${topic.label}`}</li>
    );
  });
  const type = (theme.page === 'contents') ? 'detail' : 'list';

  // 飛び先リンクのセット
  const tmp_article = JSON.parse(JSON.stringify(article));
  if (article.link_url) {
    tmp_article.target_link = article.link_url;
  }

  return (
    <li id={`rt_${type}_itemauto_${itemIndex}`} className="m-article-list-5column__item">
      <ArticleA
        article={tmp_article}
        data-atlas-trackable={`${page === "contents" ? "article" : "list"}_recommend`}
      >
        <div className="m-article-list-5column__image">
          <img src={article.image.src} alt="" />
        </div>
        <div className="m-article-list-5column__text">
          <p className="m-article-list-5column__category">{article.wappen}</p>
          <p className="m-article-list-5column__headings">{article.title}</p>
          <div className="m-tag">
            <ul className="m-tag__list">
              {tags}
            </ul>
          </div>
          <p className="m-article-list-5column__date">{article.display_time}</p>
        </div>
      </ArticleA>
    </li>
  )
}

export default RecommendItem;