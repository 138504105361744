import React, { useContext, useState, useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import { useTheme } from "styled-components";

import FavoriteItem from "../../molecules/FavoriteItem";
import { UserContext } from "../../../contexts/UserContext";

const GET_CLIPPPING_ARTICLES = gql`
  mutation (
    $token: ID,
    $user_id: ID,
    $width: Int,
    $height: Int,
  ) {
    user_clipping_articles(
      token: $token,
      user_id: $user_id
    ) {
      article {
        article_id
        title
        description
        wappen
        subchannel_name
        target_link
        display_time
        sponsor
        is_new
        movie_flag
        link_type
        direct_move_flag
        image(width: $width, height: $height, crop:true) {
          src
          etoki
          credit
        }
        topics {
          topic_id
          label
        }
      }
    }
  }
`;

const FavoriteBlock = ({post_client}) => {
  const theme = useTheme();
  const {userInfo} = useContext(UserContext);
  const [clip_items, setClipItems] = useState(null);
  const [clip_articles] = useMutation(GET_CLIPPPING_ARTICLES, { client: post_client })

  useEffect(() => {
    if(userInfo.status === 2) {
      const variables = {
        token: "FavoriteBlock",
        user_id: userInfo.id,
        width: 624,
        height: 414
      }
      clip_articles({ variables: variables }).then((val) => {
        if (val.data.user_clipping_articles && val.data.user_clipping_articles.article && val.data.user_clipping_articles.article.length > 0) {
          const items = val.data.user_clipping_articles.article.map((article, index) => {
            const props = {
              article: article,
              number: index + 1
            }
            return (
              <React.Fragment key={`news-${index}`}>
                <FavoriteItem  {...props} />
              </React.Fragment>
            );
          });
          setClipItems(items)
        }
      });
    }
  }, [userInfo, clip_articles, setClipItems]);

  return (
    <ul className={`p-${theme.page}__article-list m-article-list-3column`}>
      {clip_items}
    </ul>
  );
};

export default FavoriteBlock;