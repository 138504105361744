import React, { useRef } from "react";
import { useTheme } from "styled-components";
import adid from "../../../config/adid";
import AdInread from "../AdInread";
import RectangleAd from "../RectangleAd";

const AdSidebar = ({
  pc=false,
  sp=false,
  adtype
}) => {
  const theme = useTheme();
  const adSection = useRef();
  const noad = () => {
    adSection.current.remove();
  }
  const only = (pc && !sp) ? "is-pc" : (!pc && sp) ? "is-sp" : "";
  return (
    <>
      {(pc || sp) &&
        <section className={`p-${theme.page}__ad-sidebar-single m-ad-sidebar-single ${only}`} ref={adSection}>
          {pc &&
              <RectangleAd adid={adid.PC[theme.adpagetype][adtype]} noad={noad} viewable={true} device="pc" />
          }
          {sp &&
            <>
            {adtype==="INREAD" &&
              <AdInread adid={adid.SP[theme.adpagetype][adtype]} noad={noad} viewable={true} device="sp" />
            }
            {adtype!=="INREAD" &&
              <RectangleAd adid={adid.SP[theme.adpagetype][adtype]} noad={noad} viewable={true} device="sp" scroller={adtype==="SCROLLER"} />
            }
            </>
          }
        </section>
      }
    </>
  )
};

export default AdSidebar;