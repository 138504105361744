import React, { useEffect, useRef } from "react";
import { useTheme } from "styled-components";

import Article3ColumnInfeed from "../../ads/Article3ColumnInfeed";
import Article3ColumnItem from "../../molecules/Article3ColumnItem";
import adid from '../../../config/adid';
import Article3ColumnRectangleAd from "../../molecules/Article3ColumnRectangleAd";

const ArticleList3ColumnBlock = ({
  articles, adpage, adjustArticles
}) => {
  const theme = useTheme();
  const articleUl = useRef();

  useEffect(()=> {
    const listener = () => {
      adjustArticles();
    }

    // MutationObserverのインスタンスを作成
    let observer = new MutationObserver(function(mutationsList, observer) {
      for(let mutation of mutationsList) {
        if (mutation.type === 'childList' || mutation.type === 'subtree' || mutation.type === 'attributes') {
          listener();
        }
      }
    });
    // 監視オプション
    let config = { childList: true, subtree: true, attributes: true };
    // 監視を開始
    observer.observe(articleUl.current, config);
  },[]);

  let adindex = 1;
  const article_items = articles.map((article, index) => {
    const props = {
      article: article,
      itemIndex: index + 1
    }
    let infeed = "";
    let ad = "";
    if (index === 5) {
      infeed = (
        <>
          <Article3ColumnInfeed adid={adid.PC[theme.adpagetype].INFEED} index={adindex} page_index={adpage} device="pc" />
          <Article3ColumnInfeed adid={adid.SP[theme.adpagetype].INFEED} index={adindex} page_index={adpage} device="sp" />
        </>
      )
      adindex++;
    } else if (index === 8) {
      infeed = (
        <Article3ColumnInfeed adid={adid.SP[theme.adpagetype].INFEED} index={adindex} page_index={adpage} device="sp" />
      )
      adindex++;
    }
    return (
      <React.Fragment key={`al3c-${index}`}>
        <Article3ColumnItem  {...props} />
        {infeed}
        {ad}
      </React.Fragment>
    );
  });

  return (
    <section className={`p-${theme.page}__new`}
      data-testid="block-name-article-list">
      <div className="l-container">
        <ul className={`p-${theme.page}__new-list m-article-list-3column u-ad-single`}
          ref={articleUl} id="article-list-three-column">
            <Article3ColumnRectangleAd adid={adid.PC[theme.adpagetype].RECTANGLE1} device="pc" />
            <Article3ColumnRectangleAd adid={adid.SP[theme.adpagetype].RECTANGLE1} device="sp" />
          {article_items}
        </ul>
      </div>
    </section>
  );
};

export default ArticleList3ColumnBlock;