import React from "react";
import { gql, useQuery } from "@apollo/client";
import { useTheme } from "styled-components";
import SimpleA from "../../atoms/SimpleA";

const GET_TAG_LIST = gql`
  query {
    tag_list {
      topic_id,
      label
    }
  }
`;

const TaglistBlock = ({label}) => {
  const theme = useTheme();

  const { loading, error, data } = useQuery(GET_TAG_LIST);
  if (loading) return null;
  if (error) return null;
  if(data.tag_list.length === 0) return null;

  const tag_items = data.tag_list.map((tag, index) => {
    return (
      <React.Fragment key={`tags-${index}`}>
        <li className="m-tag__item"><SimpleA href={`/tag/${tag.topic_id}`}>{`#${tag.label}`}</SimpleA></li>
      </React.Fragment>
    );
  });
  if(tag_items.length === 0) return null;

  return (
    <div className="l-container">
      <h1 className={`p-${theme.page}__headings`}>{label}</h1>
      <section className={`m-tag p-${theme.page}__list`}>
        <ul className="m-tag__list">
          {tag_items}
        </ul>
      </section>
    </div>
  )
};

export default TaglistBlock;