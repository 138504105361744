import React, { useContext } from "react";
import { useTheme } from "styled-components";

import SearchArticleList2ColumnBlock from "../SearchArticleList2ColumnBlock";
import SideBarBlock from "../SideBarBlock";
import { QueryStringContext } from "../../../contexts/QueryStringContext";

const SearchArticleList2ColumnWithSideBarBlock = ({label}) => {
  const theme = useTheme();
  const {queryString} = useContext(QueryStringContext);
  const keyword = typeof queryString.keyword !== 'undefined' ? queryString.keyword : '';

  return (
    <section className={`p-${theme.page}__article l-article`}>
      <div className="l-container">
        <h1 className={`p-${theme.page}__headings`}>{label}</h1>
        <h2></h2>
        <div className={`p-${theme.page}__article-wrapper l-article__wrapper`}>
          <SearchArticleList2ColumnBlock keyword={keyword} />
          <SideBarBlock
            ad1={true}
            tag={true}
            ad2={true}
            ranking={true}
            prarticle_first_index={3}
            ad3_pc={true}
            banner={true}
          />
        </div>
      </div>
    </section>
  )
};

export default SearchArticleList2ColumnWithSideBarBlock;