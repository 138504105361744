import React, { useContext } from "react";
import { gql, useQuery } from "@apollo/client";
import { useTheme } from "styled-components";

import PromotionItem from "../../molecules/PromotionItem";
import { QueryStringContext } from "../../../contexts/QueryStringContext";

export const PROMOTION_BLOCK_GET_ARTICLES = gql`
  query (
    $token: ID,
    $topic_id: ID,
    $offset:Int,
    $length: Int,
    $width: Int,
    $height: Int,
    $date_staging: String
  ){
    articles(
      token: $token,
      topic_id:$topic_id,
      offset: $offset,
      length: $length,
      date_staging: $date_staging
    ){
      article {
        target_link
        title
        sponsor
        link_type
        direct_move_flag
        image(width: $width, height: $height, crop:true) {
          src
          etoki
          credit
        }
      }
    }
  }
`;

const PromotionBlock = ({
  topic_id
}) => {
  const theme = useTheme();
  const { queryString } = useContext(QueryStringContext);
  const date_staging = typeof queryString.k !== 'undefined' ? queryString.k : ''

  const { loading, error, data } = useQuery(PROMOTION_BLOCK_GET_ARTICLES, {
    variables: {
      "token": "PromotionBlock",
      "topic_id": topic_id,
      "offset": 0,
      "length": 10,
      "width": 360,
      "height": 240,
      "date_staging": date_staging
    }
  });
  if (loading) return null;
  if (error) return null;
  if(data.articles.article.length === 0) return null;

  const article_items = data.articles.article.map((article, index) => {
    const props = {
      article: article
    }
    return (
      <React.Fragment key={`promo-${index}`}>
        <PromotionItem {...props} />
      </React.Fragment>
    );
  });

  return (
    <section className={`p-${theme.page}__promotion`}
      data-testid="block-name-promotion">
      <div className="l-container">
        <h3 className="m-section-headings">PROMOTION</h3>
        <ul className="m-article-list-5column">
          {article_items}
        </ul>
      </div>
    </section>
  );
}

export default PromotionBlock;