import React from "react";
import { gql, useQuery } from "@apollo/client";
import { useTheme } from "styled-components";

import SimpleA from '../../atoms/SimpleA';

export const GET_CATEGORY_LIST = gql`
  query (
    $token: ID
  ){
    category_list(
      token: $token
    ){
      label
      topic_id
      category_path
    }
  }
`

const Nav = () => {
  const theme = useTheme();

  const { loading, error, data } = useQuery(GET_CATEGORY_LIST, {
    variables: {
      "token": "Nav"
    }
  });
  if (loading) return null;
  if (error) return null;
  if(data.category_list.length === 0) return null;

  const category_items = data.category_list.map((category, index) => {
    return (
      <React.Fragment key={`n-${index}`}>
        <li className={`p-${theme.page}__nav-item`}>
          <SimpleA href={`/${category.category_path}`} data-atlas-trackable={`category_${category.label.toLowerCase().replace(/\s+/g, "")}`}>{category.label}</SimpleA>
        </li>
      </React.Fragment>
    );
  });


  return (
    <>
      {/* nav */}
      <section className={`p-${theme.page}__nav`}>
        <div className="l-container">
          <ul className={`p-${theme.page}__nav-list`}>
            {category_items}
          </ul>
        </div>
      </section>
      {/* /nav */}
    </>
  )
};

export default Nav;