import React from "react";
import { useTheme } from "styled-components";
import SimpleA from "../../atoms/SimpleA";

const ArticleTagList = ({article_data}) => {
  const theme = useTheme();

  const tag_items = article_data.topics.map((tag, index) => {
    return (
      <React.Fragment key={`atagl-${index}`}>
        <li className="m-tag__item"><SimpleA href={`/tag/${tag.topic_id}`} data-atlas-trackable="article_tag">{`#${tag.label}`}</SimpleA></li>
      </React.Fragment>
    );
  });
  if(tag_items.length === 0) return null;

  return (
    <>
      <section className={`p-${theme.page}__tag m-section-tag`}
        data-testid="block-name-article-taglist">
        <h3 className="m-section-headings">TAG</h3>
        <SimpleA href="/taglist" className="m-section-tag__btn-view-all">VIEW ALL &gt;</SimpleA>
        <div className="m-tag">
          <ul className="m-tag__list">
            {tag_items}
          </ul>
        </div>
      </section>
    </>
  )
};

export default ArticleTagList;