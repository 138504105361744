import React, { createContext, useState } from "react";

export const FavoriteContext = createContext({});

export const FavoriteProvider = (props) => {
  const [favoriteInfo, setFavoriteInfo] = useState(props.favorite);
  return(
    <FavoriteContext.Provider value={{favoriteInfo, setFavoriteInfo}}>
      {props.children}
    </FavoriteContext.Provider>
  )
}