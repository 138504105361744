import { useState, useEffect } from "react";

const useIsVisible = (ref, options) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
      if (ref && ref.current && entry.isIntersecting && options.once) {
        observer.unobserve(ref.current);
      }
    }, options);

    if (ref && ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref && ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, options]);

  return isVisible;
}

export default useIsVisible;