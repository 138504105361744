import React from "react";
import { useTheme } from "styled-components";

import Header from "../../molecules/Header";
import MainTemplate from "../../templates/MainTemplate";
import Consts from "../../../config/Consts";

const InfoAbout = ({
  post_client,
  helmet
}) => {
  const theme = useTheme();

  const chanel_list = [
    {href:"/watchjewelry", image:"/images/about_watch@2x.jpg", alt:"Watch & Jewelry", wappen:"Watch & Jewelry", title:"時計&宝石"},
    {href:"/fashion", image:"/images/about_fashion@2x.jpg", alt:"Fashion", wappen:"Fashion", title:"ファッション"},
    {href:"/healthbeauty", image:"/images/about_health@2x.jpg", alt:"Health & Beauty", wappen:"Health & Beauty", title:"健康&美容"},
    {href:"/sustainable", image:"/images/about_sustainable@2x.jpg", alt:"Sustainable", wappen:"Sustainable", title:"サステナブル"},
    {href:"/people", image:"/images/about_people@2x.jpg", alt:"People", wappen:"People", title:"人"},
    {href:"/lifestyle", image:"/images/about_lifestyle@2x.jpg", alt:"Lifestyle", wappen:"Lifestyle", title:"ライフスタイル"},
    {href:"/culture", image:"/images/about_culture@2x.jpg", alt:"Culture", wappen:"Culture", title:"文化"},
    {href:Consts.LINK.MAIL_MAGAZINE_URL, image:"/images/about_mailmagazine@2x.png", alt:"Mail magazine", wappen:"Mail magazine", title:"メールマガジン登録"},
    {href:"/taglist", image:"/images/about_tag@2x.png", alt:"Tags", wappen:"Tags", title:"タグ一覧"}
  ]
  const list = chanel_list.map((channel, index) => {
    return (
      <li className={`p-${theme.page}__index-item m-article-list-3column__item`} key={`${theme.page}-${index}`} >
        <a href={channel.href}>
          <div className="m-article-list-3column__image">
            <img src={channel.image} alt={channel.alt} />
          </div>
          <div className="m-article-list-3column__text">
            <p className="m-article-list-3column__category">{channel.wappen}</p>
            <p className="m-article-list-3column__headings">{channel.title}</p>
          </div>
        </a>
      </li>
    );
  });

  return (
    <MainTemplate
      helmet={helmet}
      header={
        <Header post_client={post_client} />
      }
      main={
        <div className="l-container">
          <h1 className={`p-${theme.page}__headings is-pc`}>
            <img src="/images/about_headings@2x.png" alt="いまのラグジュアリー" />
          </h1>
          <h1 className={`p-${theme.page}__headings is-sp`}>
            <img src="/images/about_headings_sp@2x.png" alt="いまのラグジュアリー" />
          </h1>

          <div className="l-inner">
            <p className={`p-${theme.page}__text`}>「THE NIKKEI MAGAZINE」はポストコロナ時代に輝きを放つ「真のラグジュアリー」を探求し、今こそ実践したい装いと生活スタイルをビジネスパーソンに提案する、日本経済新聞社のサイトです。</p>
            <p className={`p-${theme.page}__text`}>発信するのは、質が高く、味わい深いモノ・コトの数々。好奇心が旺盛で知的な読者の「知りたい」「見たい」「買いたい」といったWANTSを刺激する情報やコラムを満載し、人生を豊かにするアートやサステナブル・ライフの知識を提供します。読者参加型のコンテンツも展開し、あなたにふさわしい価値の発見をサポートします。</p>

            <h2 className={`p-${theme.page}__headings-h2`}>いまのラグジュアリー</h2>
            <p className={`p-${theme.page}__text`}>時の流れとともに「ラグジュアリー」という言葉の意味は変化しつつあります。かつてのような「リッチで華やか」という外面的なイメージにとどまらず、価値観が多様化した現代では、スタイリッシュであるとともに、情緒豊かで内面も輝く「生き方」そのものの表現としてとらえられます。</p>
            <p className={`p-${theme.page}__text`}>私たちは、単なる流行に左右されず、「自分らしいライフスタイル」を自由に追い求める成熟した人々こそが、ラグジュアリーの本質を理解できると考えます。「THE NIKKEI MAGAZINE」は、魅力的なアイテムや優れたデザインを提案するだけでなく、その背景にある職人技や歴史ストーリーなども併せて紹介し、所有する喜び、体験する楽しさを、よりディープに、しなやかにお伝えします。</p>
          </div>

          <div className={`p-${theme.page}__index`}>
            <ul className={`p-${theme.page}__index-list m-article-list-3column`}>
              {list}
            </ul>

          </div>

          <div className={`p-${theme.page}__club`}>
            <div className={`p-${theme.page}__club-text`}>
              <h2 className={`p-${theme.page}__club-headings`}>THE NIKKEI MAGAZINE CLUB</h2>
              <p className={`p-${theme.page}__club-lead`}>「THE NIKKEI MAGAZINE」読者のための会員組織「THE NIKKEI MAGAZINE CLUB」会員先行コンテンツが楽しめるメールマガジンや会員限定イベントへのご招待といったサービスも提供します。ぜひこの機会にご登録ください。</p>
              <a href={Consts.LINK.MAIL_MAGAZINE_URL} className={`p-${theme.page}__club-link`}>登録はこちら</a>
            </div>
            <div className={`p-${theme.page}__club-image`}>
              <img src="/images/about_club@2x.jpg" alt="" />
            </div>
          </div>
        </div>
      }
    />
  )
};

export default InfoAbout;