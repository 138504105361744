import React, { useContext, useRef } from "react";
import { ThemeProvider } from 'styled-components';
import { gql, useQuery } from "@apollo/client";

import { QueryStringContext } from "../../../contexts/QueryStringContext";
import AdTop from "../../ads/AdTop";
import Header from "../../molecules/Header";
import Nav from "../../molecules/Nav";
import HtmlBanner from "../../molecules/HtmlBanner";
import ArticleList1ColumnWithSideBarBlock from "../../organisms/ArticleList1ColumnWithSideBarBlock";
import ArticleList3ColumnBlock from "../../organisms/ArticleList3ColumnBlock";
import InformationBlock from "../../organisms/InformationBlock";
import MagazineBlock from "../../organisms/MagazineBlock";
import NewsBlock from "../../organisms/NewsBlock";
import PromotionBlock from "../../organisms/PromotionBlock";
import PushBlock from "../../organisms/PushBlock";
import TopicsBlock from "../../organisms/TopicsBlock";
import MainTemplate from "../../templates/MainTemplate";
import adid from '../../../config/adid';
import AdInread from "../../ads/AdInread";
import Consts from "../../../config/Consts";
import addslashes from "../../../utils/AddSlashes";

export const GET_ARTICLES = gql`
  query (
    $token: ID,
    $offset:Int,
    $length: Int,
    $width: Int,
    $height: Int,
    $date_staging: String
  ){
    search_articles(
      token: $token
      offset: $offset
      length: $length
      date_staging: $date_staging
    ){
      total
      article_list {
        article_id
        title
        sub_title
        wappen
        image(width:$width, height: $height){
          src
          etoki
        }
        snippet
        target_link
        sponsor
        is_new
        movie_flag
        link_type
        link_url
        direct_move_flag
        topics {
          topic_id
          label
        }
      }
    }
  }
`;

const TopPage = ({post_client}) => {
  const { queryString } = useContext(QueryStringContext);
  const date_staging = typeof queryString.k !== 'undefined' ? queryString.k : ''

  const adSection = useRef();

  const { loading, error, data } = useQuery(GET_ARTICLES, {
    variables: {
      "token": "TopPage",
      "offset": 0,
      "length": 18,
      "width": 624,
      "height": 414,
      "date_staging": date_staging
    }
  });

  const mode = 'default';
  const page = 'index';
  const adpagetype = 'TOP';

  if (loading) return null;
  if (error) return null;

  let articles_area1 = []
  let articles_area2 = []
  if(data.search_articles.article_list.length !== 0) {
    // S1_6総合TOP_NEW記事_2 9件＋広告
    articles_area1 = data.search_articles.article_list.slice(0, 11)
    // S1_9総合TOP_NEW記事_3 9件＋広告
    articles_area2 = data.search_articles.article_list.slice(9, 18)
  }

  const title = 'THE NIKKEI MAGAZINE｜日本経済新聞社'
  const description = Consts.COMMON.DESCRIPTION
  const analyticsVars = 'var analyticsVars = {title:"' + addslashes(title) + '"};';
  const helmet = [
    <title key='h-title'>{title}</title>,
    <meta key='h-meta-1' name="description" content={description} />,
    <meta key='h-meta-2' property="og:title" content={title} />,
    <meta key='h-meta-3' property="og:description" content={description} />,
    <script key='h-script-1' type="text/javascript">{analyticsVars}</script>
  ]

  const adjustArticles = () => {
    const ul = document.getElementById("article-list-three-column");
    const li = Array.prototype.slice.call(ul.getElementsByTagName("li"))
      .filter((e)=> !(e.classList.contains("m-tag__item") || e.classList.contains("is-sp")));
    const double = ul.classList.contains("u-ad-double");
    const next_ul = document.getElementById("article-list-one-column");
    const next_li = Array.prototype.slice.call(next_ul.getElementsByTagName("li"))
      .filter((e)=> !(e.classList.contains("m-tag__item")
                      || e.classList.contains("is-sp")
                      || e.classList.contains("u-infeed")));

    const showHideLi = function(hideA) {
      let lis = Array.from(next_li).fill(0);
      let i = 0;
      next_li.forEach((e) => {
        if(hideA.includes(e.firstChild.href)){
          lis[i] = 1;
        }
        i++;
      });
      let j = 0;
      let k = 1;
      lis.forEach((l) => {
        const link = next_li[j].getElementsByTagName("a")
        if(l === 1) {
          next_li[j].setAttribute("style", "display:none;")
          link[0].removeAttribute("data-atlas-trackable")
        } else {
          next_li[j].setAttribute("style", "display:block;")
          link[0].setAttribute("data-atlas-trackable", "top_new_lower_" + k++)
        }
        j++;
      })
    }

    let hideA = [];
    if(double) {
      // [10]までは表示されている
      for(let i=8; i <= 10; i++) {
        hideA.push(li[i].firstChild.href);
      }
    } else {
      // [11]までは表示されている
      for(let i=9; i <= 11; i++) {
        if(li.length > i) {
          hideA.push(li[i].firstChild.href);
        }
      }
    }
    showHideLi(hideA);
  };

  const removeSection = () => {
    adSection.current.remove();
  }

  return (
    <ThemeProvider theme={{ mode, page, adpagetype }}>
      <MainTemplate
        helmet={helmet}
        header={
          <Header isTop={true} post_client={post_client} />
        }
        main={
          <>
            <AdTop adid={adid.PC[adpagetype].BILLBOARD} device="pc" />
            <AdTop adid={adid.SP[adpagetype].BILLBOARD} device="sp" />
            <Nav />
            <PushBlock />
            <NewsBlock topic_id={Consts.ID.NEWS} />
            <ArticleList3ColumnBlock
              articles={articles_area1}
              adpage={1}
              adjustArticles={adjustArticles}
            />
            <PromotionBlock topic_id={Consts.ID.PROMOTION} />
            <TopicsBlock />
            <section className="p-index__ad-middle m-ad-middle is-pc" ref={adSection}>
              <div className="l-container">
                <AdInread adid={adid.PC[adpagetype].INREAD} device="pc" noad={removeSection} />
              </div>
            </section>
            <AdInread adid={adid.SP[adpagetype].INREAD} device="sp" />
            <ArticleList1ColumnWithSideBarBlock
              articles={articles_area2}
              adpage={2} />
            <MagazineBlock />
            <InformationBlock topic_id={Consts.ID.INFORMATION} />
            <HtmlBanner device="sp" />
          </>
        }
      />
    </ThemeProvider>
  )
}

export default TopPage;