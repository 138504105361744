import React, { useContext } from "react";
import { gql, useQuery } from "@apollo/client";
import { useTheme } from "styled-components";

import InformationItem from "../../molecules/InformationItem";
import { QueryStringContext } from "../../../contexts/QueryStringContext";

export const INFORMATION_BLOCK_GET_ARTICLES = gql`
  query (
    $token: ID,
    $topic_id: ID,
    $offset:Int,
    $length: Int,
    $width: Int,
    $height: Int,
    $date_staging: String
  ){
    articles(
      token: $token,
      topic_id: $topic_id,
      offset: $offset,
      length: $length,
      date_staging: $date_staging
    ){
      article {
        article_id
        title
        description
        wappen
        subchannel_name
        target_link
        display_time
        sponsor
        is_new
        movie_flag
        link_type
        direct_move_flag
        image(width: $width, height: $height, crop:true) {
          src
          etoki
          credit
        }
        topics {
          topic_id
          label
        }
      }
      page_info {
        offset
        length
        next_offset
        max
        has_next
      }
    }
  }
`;

const InformationBlock = ({
  topic_id
}) => {
  const theme = useTheme();
  const { queryString } = useContext(QueryStringContext);
  const date_staging = typeof queryString.k !== 'undefined' ? queryString.k : ''

  const { loading, error, data } = useQuery(INFORMATION_BLOCK_GET_ARTICLES, {
    variables: {
      "token": "InformationBlock",
      "topic_id": topic_id,
      "offset": 0,
      "length": 5,
      "width": 360,
      "height": 240,
      "date_staging": date_staging
    }
  });

  if (loading) return null;
  if (error) return null;
  if(data.articles.article.length === 0) return null;

  const information_items = data.articles.article.map((article, index) => {
    const props = {
      article: article
    }
    return (
      <React.Fragment key={`i-${index}`}>
        <InformationItem {...props} />
      </React.Fragment>
    );
  });
  if(information_items.length === 0) return null;

  return (
    <section className={`p-${theme.page}__information`}
      data-testid="block-name-information">
      <div className="l-container">
        <h3 className="m-section-headings">INFORMATION</h3>
        <ul className="m-article-list-5column">
          {information_items}
        </ul>
      </div>
    </section>
  )
};

export default InformationBlock;