import React from "react";
import { gql, useQuery } from "@apollo/client";
import { useTheme } from "styled-components";

import RecommendInfeed from "../../ads/RecommendInfeed";
import RecommendItem from "../../molecules/RecommendItem";
import adid from '../../../config/adid';

export const GET_TOP_ARTICLES = gql`
  query (
    $token: ID,
    $offset:Int,
    $length: Int,
    $width: Int,
    $height: Int
  ){
    search_articles(
      token: $token
      offset: $offset
      length: $length
    ){
      article_list {
        title
        image(width: $width, height: $height, crop:true) {
          src
          etoki
        }
        target_link
        link_type
        link_url
        direct_move_flag
        wappen
        display_time
        topics {
          label
        }
      }
    }
  }
`;

const RecommendBlock = () => {
  const theme = useTheme();

  const variables = {
    "token": "RecommendBlock",
    "offset": 0,
    "length": (theme.page === 'contents') ? 8 : 5,
    "width": 360,
    "height": 240
  }

  const { loading, error, data } = useQuery(GET_TOP_ARTICLES, {variables: variables});
  if (loading) return null;
  if (error) return null;
  const articles = data.search_articles.article_list;
  if(articles.length === 0) return null;

  const type = (theme.page === 'contents') ? 'detail' : 'list';
  const insert_infeed = (theme.page === 'contents') ? [4,8] : false;
  const article_items = articles.map((article, index) => {
    const props = {
      article: article,
      itemIndex: index+1,
      page: theme.page
    }
    let infeed = "";
    if(insert_infeed) {
      if(insert_infeed.indexOf(index + 1) !== -1) {
        infeed = (
          <li className="m-article-list-5column__item">
            <div className="is-pc">
              <RecommendInfeed adid={adid.PC[theme.adpagetype].INFEED} index={index+1} page_index={1} device="pc" />
            </div>
            <div className="is-sp">
              <RecommendInfeed adid={adid.SP[theme.adpagetype].INFEED} index={index+1} page_index={1} device="sp" />
            </div>
          </li>
        )
      }
    }
    return (
      <React.Fragment key={`r-${index}`}>
        <RecommendItem {...props} />
        {infeed}
      </React.Fragment>
    );
  });
  if(article_items.length === 0) return null;

  return (
    <section id={`rt_${type}_itemauto`} className={`p-${theme.page}__recommend`}
      data-testid="block-name-recommend">
      <div className="l-container">
        <h3 className="m-section-headings">RECOMMEND</h3>
        <ul className="m-article-list-5column">
          {article_items}
        </ul>
      </div>
    </section>
  )
};

export default RecommendBlock;