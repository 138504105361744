import React from "react";
import { useTheme } from "styled-components";

import ArticleList1ColumnBlock from "../ArticleList1ColumnBlock";
import SideBarBlock from "../SideBarBlock";

const ArticleList1ColumnWithSideBarBlock = ({
  articles, adpage
}) => {
  const theme = useTheme();

  return (
    <div className={`p-${theme.page}__article l-article`}>
      <div className="l-container">
        {/* article wrapper */}
        <div className={`p-${theme.page}__article-wrapper  l-article__wrapper`}>
          <ArticleList1ColumnBlock
            articles={articles}
            adpage={adpage}
          />
          <SideBarBlock
            tag={true}
            ad2={true}
            ad2_sp={true}
            ranking={true}
            banner={true}
          />
        </div>
      </div>
    </div>
  )
};

export default ArticleList1ColumnWithSideBarBlock;