import React, { createContext, useState } from "react";

export const QueryStringContext = createContext({});

export const QueryStringProvider = (props) => {
  const [queryString, setQueryString] = useState(props.qs);
  const [pathname, setPathname] = useState(props.pathname);
  return(
    <QueryStringContext.Provider value={{queryString, setQueryString, pathname, setPathname}}>
      {props.children}
    </QueryStringContext.Provider>
  )
}