import React, { useState, useEffect, useContext, useRef } from "react";
import { gql, useMutation } from "@apollo/client";
import { useTheme } from "styled-components";

import Consts from "../../../config/Consts";
import { UserContext } from "../../../contexts/UserContext";
import SimpleA from "../SimpleA";

const USER_NOTICE = gql`
  mutation (
    $token: ID,
    $mode: Int,
    $user_id: ID,
    $notice_id: [ID]
  ) {
    user_notice(
      token: $token,
      mode: $mode,
      user_id: $user_id,
      notice_id: $notice_id
    ) {
      code
      result
    }
  }
`;

const NoticeMenu = ({
  post_client,
  id
}) => {
  const theme = useTheme();
  const [notice_icon, setNoticeIcon] = useState(null);
  const [notice] = useMutation(USER_NOTICE, { client: post_client })
  const { userInfo } = useContext(UserContext);
  const noticeMenu = useRef();

  useEffect(() => {
    if(theme.page !== 'notice') {
      const variables = {
        token: "NoticeMenu",
        mode: 0,
        user_id: id,
        notice_id: Consts.ID.NOTICE_TAG[userInfo.sex] // 0:unknow,1:men,2:women,3:その他,4:回答しない
      }
      notice({ variables: variables }).then((val) => {
        if (val.data.user_notice && val.data.user_notice.code > 0) {
          noticeMenu.current.setAttribute('style', 'display:block');
          if(parseInt(val.data.user_notice.result, 10) > 0) {
            setNoticeIcon(<span className="header__member-menu-icon"></span>)
          }
        }
      });
    } else {
      noticeMenu.current.setAttribute('style', 'display:block');
    }
  }, [theme.page, notice, id, setNoticeIcon]);

  return (
    <li className="header__member-menu-item" style={{ display: 'none' }} ref={noticeMenu}>
      <SimpleA href={Consts.LINK.NOTICE_URL}>あなたへのお知らせ{notice_icon}</SimpleA>
    </li>
  )
};

export default NoticeMenu;