import React from "react";
import { gql, useQuery } from "@apollo/client";
import { useTheme } from "styled-components";

import AdBanner from "../../ads/AdBanner";

export const GET_BANNER_LIST = gql`
  query (
    $token: ID,
    $width: Int,
    $height: Int
  ) {
    banner_list(
      token: $token
    ) {
      article {
        image(width: $width, height: $height, crop:true, disregard_height:true) {
          src
          etoki
          link_url
          link_url_transition_screen_flg
        }
      }
    }
  }
`;

const HtmlBanner = ({
  device
}) => {
  const theme = useTheme();

  const { loading, error, data } = useQuery(GET_BANNER_LIST, {
    variables: {
      "token": "HtmlBanner",
      "width": 600,
      "height": 200
    }
  });
  if (loading) return null;
  if (error) return null;

  const banner_html = data.banner_list.article.map((article, index) => {
    const {
      src,
      etoki,
      link_url,
      link_url_transition_screen_flg
    } = article.image
    return `
      <a href="${link_url}" target="${link_url_transition_screen_flg === 1 ? '_blank' : ''}" rel="${link_url_transition_screen_flg === 1 ? 'noopener' : ''}" data-atlas-trackable="banner_${index+1}">
        <img src="${src}" alt="${etoki}">
      </a>
    `
  });

  if(banner_html.every((value) => value === null || value.length === 0)) return null;
  const banner_items = banner_html.map((banner, index) => {
    return (<AdBanner key={`b-${device}-${index}`} html={banner} />);
  });

  return (
    <section className={`p-${theme.page}__banner m-banner ${device==='sp'?'is-sp':'is-pc'}`}
      data-testid="block-name-banner-link">
      <ul className="m-banner-list">
        {banner_items}
      </ul>
    </section>
  )
};

export default HtmlBanner;