import React from "react";
import { useTheme } from "styled-components";
import adid from "../../../config/adid";
import ArticleListPrItem from "../../ads/ArticleListPrItem";

const ArticleListPr = ({
  adpage, first_adindex
}) => {
  const theme = useTheme();
  let adindex_pc = first_adindex;
  let adindex_sp = first_adindex;
  return (
    <section className={`p-${theme.page}__pr`}>
      <ul className="p-article-index__pr-list m-article-list-pr">
        <ArticleListPrItem adid={adid.PC[theme.adpagetype].INFEED} index={adindex_pc++} page_index={adpage} device="pc" />
        <ArticleListPrItem adid={adid.PC[theme.adpagetype].INFEED} index={adindex_pc++} page_index={adpage} device="pc" />
        <ArticleListPrItem adid={adid.PC[theme.adpagetype].INFEED} index={adindex_pc++} page_index={adpage} device="pc" />
        <ArticleListPrItem adid={adid.SP[theme.adpagetype].INFEED} index={adindex_sp++} page_index={adpage} device="sp" />
        <ArticleListPrItem adid={adid.SP[theme.adpagetype].INFEED} index={adindex_sp++} page_index={adpage} device="sp" />
        <ArticleListPrItem adid={adid.SP[theme.adpagetype].INFEED} index={adindex_sp++} page_index={adpage} device="sp" />
      </ul>
    </section>
  )
};

export default ArticleListPr;