import React from "react";
import Consts from "../../../config/Consts";
import SimpleA from "../../atoms/SimpleA";

const MagazineItem = ({
  magazine,
  linkToList = false
}) => {
  return (
    <>
      <li className="p-index__magazine-item">
        <div className="p-index__magazine-image">
          {magazine.image.link_url ? (
            <SimpleA href={magazine.image.link_url} aria-label={magazine.title} {...(magazine.image.link_url_transition_screen_flg === 1) ?
              { target: "_blank", rel: "noopener" } : {}
            } data-atlas-trackable={`top_magazine_${linkToList?"ai":"tnm"}`}>
              <img src={magazine.image.src} alt="" />
            </SimpleA>
          ):
          <img src={magazine.image.src} alt="" />
          }
        </div>
        <div className="p-index__magazine-text">
          <p className="p-index__magazine-category">{magazine.title}</p>
          <p className="p-index__magazine-headings">{magazine.description}</p>
          <SimpleA href={magazine.binary_link}
            className="p-index__magazine-link"
            data-atlas-trackable={`top_magazine_${linkToList?"ai":"tnm"}`}
            {...(magazine.binary_link_type === 1) ?
              { target: "_blank", rel: "noopener" } : {}
            }>&gt; 最新号を見る</SimpleA>
          {linkToList &&
            <SimpleA href={Consts.LINK.AI_URL} className="p-index__magazine-btn-article-list" data-atlas-trackable="top_magazine_ai_list">記事一覧</SimpleA>
          }
        </div>
      </li>
    </>
  )
};

export default MagazineItem;