import React, { useContext, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { useTheme } from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination } from "swiper";

import SliderItem from '../../molecules/SliderItem';
import { QueryStringContext } from "../../../contexts/QueryStringContext";

export const GET_CAROUSEL = gql` 
  query ( $token: ID,
          $topic_id: ID,
          $width: Int,
          $height: Int,
          $width_sp: Int,
          $height_sp: Int,
          $date_staging: String
        )
  {
    carousel(
      token: $token,
      topic_id: $topic_id,
      date_staging: $date_staging) {
      article {
        title
        snippet
        target_link
        sponsor
        wappen
        subchannel_name
        is_new
        movie_flag
        link_type
        direct_move_flag
        image(width: $width, height: $height, crop:true) {
          src
          etoki
          credit
        }
        square_image(width: $width_sp, height: $height_sp, crop:true) {
          src
          etoki
          credit
        }
        topics {
          topic_id
          label
        }
      }
    }
  }
`;

const Slider = ({
  topic_id
}) => {
  const theme = useTheme();
  const { queryString } = useContext(QueryStringContext);
  const date_staging = typeof queryString.k !== 'undefined' ? queryString.k : ''

  useEffect(() => {
    //swiperのデフォルトのpaginationにSVGアニメーションの要素のHTMLをappend
    const elem = document.getElementsByClassName("swiper-pagination-bullet");
    for(let i=0;i<elem.length;i++) {
      const fragment = document
      .createRange()
      .createContextualFragment('<div class="swiper-pagination__seek"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 82 82" width="82" height="82"><circle cx="41" cy="41" r="40"></circle></svg></div><div class="swiper-pagination__num">' + (i+1) + '</div>');
      elem[i].appendChild(fragment);
    }
  }, []);

  const variables = {
    "token": "Slider",
    "topic_id": topic_id,
    "width": 1308,
    "height": 870,
    "width_sp": 1500,
    "height_sp": 1500,
    "date_staging": date_staging
  };

  const { loading, error, data } = useQuery(GET_CAROUSEL, {
    variables: variables
  });
  if (loading) return null;
  if (error) return null;

  const slider_items = data.carousel.article.map((article, index) => {
    const props = {
      article: article
    }
    return (
      <SwiperSlide key={`slider-${index}`}>
        <SliderItem  {...props} />
      </SwiperSlide>
    );
  });
  if (slider_items.length === 0) return null;

  return (
    <Swiper
      slidesPerView={1}
      navigation={true}
      speed={3000}
      pagination={{
        el: '.swiper-pagination', // paginationのクラス名
        clickable: true,          // paginationをクリックできるようにするか
      }}
      autoplay={{
        delay: 3000,                    // スライド間の遷移の遅延
        disableOnInteraction: false,    // ユーザがクリック後に自動再生を継続する
      }}
      loop={true}
      modules={[Navigation, Autoplay, Pagination]}
      className={`p-${theme.page}__slider swiper`}
    >
      {slider_items}
      <div className="swiper-pagination"></div>
    </Swiper>
  );
}

export default Slider;