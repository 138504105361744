import React from 'react';
import { useTheme } from "styled-components";

import SimpleA from '../../atoms/SimpleA';

const TopicsItem = ({
  article
}) => {
  const theme = useTheme();
  return (
    <li className="m-article-list-topics__item">
      <SimpleA
        href={article.target_link}
        data-atlas-trackable={`${theme.page === "index" ? "top" : "list"}_topics`}
        {...(article.link_type === 3) ?
          { target: "_blank", rel: "noopener" } : {}
        }
      >
        <div className="m-article-list-topics__image">
          <img src={article.image.src} alt="" />
        </div>
        <div className="m-article-list-topics__text">
          <p className="m-article-list-topics__headings">{article.title}</p>
        </div>
      </SimpleA>
    </li>
  )
};

export default TopicsItem;