import { Component } from "react";
import React from "react";
import { PropTypes } from "prop-types";

class ScriptTag extends Component {
  componentDidMount() {
    const { src, async, defer } = this.props;
    const script = document.createElement("script");

    script.src = src;
    script.async = async || false;
    script.defer = defer || false;

    if (document.getElementById(this.props.sid) !== null) {
      const a = document.getElementById(this.props.sid);
      a.parentNode.replaceChild(script, a);
    }
  }

  render() {
    return <div id={this.props.sid} />
  }
}

ScriptTag.propTypes = {
  src: PropTypes.string.isRequired,
  async: PropTypes.bool
};

export default ScriptTag
