import React from "react";
import Consts from "../../../config/Consts";
import SimpleA from "../../atoms/SimpleA";

const NikkeiFooter = () => {
  return (
    <div id="nikkei-copyright" className="brand_white">
      <div className="nikkei-copyright_inner">
        <span className="nikkei-copyright_logo"><SimpleA href={Consts.LINK.NIKKEI_URL} target="_blank" rel="noopener">
          <img src="/images/common/logo-nikkei_white.svg" alt="NIKKEI" /></SimpleA></span><span className="nikkei-copyright_text">No reproduction without permission.</span>
      </div>
    </div>
  )
};

export default NikkeiFooter;