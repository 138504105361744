import React from "react";
import { useTheme } from "styled-components";

import Article1ColumnInfeed from "../../ads/Article1ColumnInfeed";
import Article1ColumnItem from "../../molecules/Article1ColumnItem";
import adid from "../../../config/adid";

const ArticleList1ColumnBlock = ({
  articles, adpage
}) => {
  const theme = useTheme();

  let adindex = 3;
  const insert_infeed = [2];
  const article_items = articles.map((article, index) => {
    const props = {
      article: article,
      itemIndex: index + 1
    }
    let infeed = "";
    if(insert_infeed) {
      if(insert_infeed.indexOf(index + 1) !== -1) {
        infeed = (
          <>
            <Article1ColumnInfeed adid={adid.PC[theme.adpagetype].INFEED} index={adindex} page_index={adpage} device="pc" />
            <Article1ColumnInfeed adid={adid.SP[theme.adpagetype].INFEED} index={adindex} page_index={adpage} device="sp" />
          </>
        )
        adindex++;
      }
    }
    return (
      <React.Fragment key={`al3c-${index}`}>
        <Article1ColumnItem {...props} />
        {infeed}
      </React.Fragment>
    );
  });

  return (
    <div className={`p-${theme.page}__article-main l-article__main`}>
      <ul className={`p-${theme.page}__article-list m-article-list-1column`}
        id="article-list-one-column">
        {article_items}
        <Article1ColumnInfeed adid={adid.PC[theme.adpagetype].INFEED} index={adindex} page_index={adpage} device="pc" />
      </ul>
    </div>
  )
};

export default ArticleList1ColumnBlock;