import React from "react";
import { useTheme } from "styled-components";

import SimpleA from "../../atoms/SimpleA";
import ArticleBody from "../ArticleBody";

const NewsBody = ({
  article_detail
}) => {
  const theme = useTheme();
  const tags = article_detail.article_data.topics.map((tag, index) => {
    return (
      <li key={`tag-n-${theme.page}-${index}`} className="m-tag__item"><SimpleA href={`/tag/${tag.topic_id}`}>{`#${tag.label}`}</SimpleA></li>
    );
  });
  return (
    <>
      <section className={`p-${theme.page}__top m-contents-top`}>
        <div className="m-contents-top__text">
          <p className="m-contents-top__category">{article_detail.article_data.wappen}</p>
          <h1 className="m-contents-top__headings">{article_detail.article_data.sub_title_1}</h1>
          <h2></h2>
          <h3 className="m-contents-top__headings-h3">{article_detail.article_data.sub_title_detail}</h3>
          <h2 className="m-contents-top__headings-h2">{article_detail.article_data.sub_name}</h2>
          <div className="m-tag">
            <ul className="m-tag__list">
              {tags}
            </ul>
          </div>
          <p className="m-contents-top__date">{article_detail.article_data.display_time}</p>
        </div>
      </section>

      <div className={`p-${theme.page}__article l-article`}>
        <div className="l-container">
          <ArticleBody article_detail={article_detail} />
        </div>
      </div>
    </>
  )
};

export default NewsBody;