import React from "react";
import { gql, useQuery } from "@apollo/client";
import { useTheme } from "styled-components";

import MagazineItem from "../../molecules/MagazineItem";

export const GET_MAGAZINE = gql`
  query (
    $token: ID,
    $kind: String,
    $length: Int,
    $width: Int,
    $height: Int
  ) {
    articles(
      token: $token
      kind: $kind
      length: $length
    ) {
      article {
        title
        description
        image(width: $width, height: $height, crop:true, disregard_height:true) {
          src
          etoki
          link_url
          link_url_transition_screen_flg
        }
        binary_link
        binary_link_type
      }
    }
  }
`;

const MagazineBlock = () => {
  const theme = useTheme();

  const { loading, error, data } = useQuery(GET_MAGAZINE, {
    variables: {
      "token": "MagazineBlock",
      "kind": "magazine",
      "length": 2,
      "width": 380,
      "height": 500
    }
  });

  if (loading) return null;
  if (error) return null;
  if(data.articles.article.length === 0) return null;

  const magazine_items = data.articles.article.map((magazine, index) => {
    const props = {
      magazine: magazine,
      linkToList: index!==0
    }
    return (
      <React.Fragment key={`magazine-${index}`}>
        <MagazineItem {...props} />
      </React.Fragment>
    );
  });

  return (
    <section className={`p-${theme.page}__magazine`}
      data-testid="block-name-magazine">
      <div className="l-container">
        <h3 className="m-section-headings">MAGAZINE</h3>
        <ul className={`p-${theme.page}__magazine-list`}>
          {magazine_items}
        </ul>
      </div>
    </section>
  )
};

export default MagazineBlock;