import React from "react";
import { gql, useQuery } from "@apollo/client";
import { useTheme } from "styled-components";
import Consts from "../../../config/Consts";
import SimpleA from "../../atoms/SimpleA";

export const GET_TAG_LIST = gql`
  query {
    tag_list {
      topic_id,
      label
    }
  }
`;

const TagBlock = ({
  space=false,
}) => {
  const theme = useTheme();

  const { loading, error, data } = useQuery(GET_TAG_LIST);
  if (loading) return null;
  if (error) return null;
  if(data.tag_list.length === 0) return null;
  let atlas_name = (theme.page === "index" ? "top" : "list") + "_tag"

  const tag_items = data.tag_list.map((tag, index) => {
    if(index < Consts.LIMIT.TAG_COUNT) {
      return (
        <React.Fragment key={`tagsb-${index}`}>
          <li className="m-tag__item"><SimpleA href={`/tag/${tag.topic_id}`} data-atlas-trackable={atlas_name}>{`#${tag.label}`}</SimpleA></li>
        </React.Fragment>
      );
    } else {
      return null;
    }
  });

  return (
    <section className={`p-${theme.page}__tag m-section-tag${space?' i-side':''}`}
      data-testid="block-name-taglist">
      <h3 className="m-section-headings">TAG</h3>
      <SimpleA href="/taglist" className="m-section-tag__btn-view-all">VIEW ALL &gt;</SimpleA>
      <div className="m-tag">
        <ul className="m-tag__list">
          {tag_items}
        </ul>
      </div>
    </section>
  )
};

export default TagBlock;