import React from "react";

import RankingItem from "../../molecules/RankingItem";

const RankingList = ({
  ranking
}) => {
  const ranking_items = ranking.map((ranking, index) => {
    const props = {
      article: ranking.article
    }
    return (
      <React.Fragment key={`rl-${index}`}>
        <RankingItem {...props} />
      </React.Fragment>
    );
  });
  if(ranking_items.length === 0) return null;

  return (
    <div className="swiper-slide">
      <ul className="m-ranking-contents">
        {ranking_items}
      </ul>
    </div>
  )
};

export default RankingList;