import React from "react";
import { ThemeProvider } from "styled-components";
import { useParams } from 'react-router-dom';
import InfoToc from "../../organisms/InfoToc";
import InfoAbout from "../../organisms/InfoAbout";
import addslashes from "../../../utils/AddSlashes";
import Consts from "../../../config/Consts";

const InfoPage = ({post_client}) => {
  let { page_name } = useParams();

  const mode = 'default';
  let page = '';
  const adpagetype = '';

  let title = '';
  let description = Consts.COMMON.DESCRIPTION
  if(page_name === "toc.html") {
    title = "利用規約｜THE NIKKEI MAGAZINE｜日本経済新聞社"
    page = 'policy'
    description = '「THE NIKKEI MAGAZINE CLUB」の会員規約です。' + Consts.COMMON.DESCRIPTION
  } else if(page_name === "about.html") {
    title = "THENIKKEIMAGAZINE　いまのラグジュアリー | 日本経済新聞社"
    page = 'about'
  }

  const main_contents = () => {
    const analyticsVars = 'var analyticsVars = {title:"' + addslashes(title) + '"};';
    const params = {
      post_client: post_client,
      helmet: [
        <title key='h-title'>{title}</title>,
        <meta key='h-meta-1' name="description" content={description} />,
        <meta key='h-meta-2' property="og:title" content={title} />,
        <meta key='h-meta-3' property="og:description" content={description} />,
        <link key='h-canonical' rel="canonical" href={`${Consts.COMMON.BASE_URL}/info${page_name?'/'+page_name:''}`} />,
        <script key='h-script-1' type="text/javascript">{analyticsVars}</script>
      ]
    }
    if(page_name === "toc.html") {
      return <InfoToc {...params} />
    } else if(page_name === "about.html") {
      return <InfoAbout {...params} />
    } else {
      throw new Error('NOTFOUND');
    }
  }

  return (
    <ThemeProvider theme={{ mode, page, adpagetype }}>
      {main_contents()}
    </ThemeProvider>
  )
};

export default InfoPage;