import React, { useRef } from "react";
import Menu from "../Menu";

const HeaderLeft = ({
  headerRef
}) => {
  const headerMenuRef = useRef();
  const menuRef = useRef();
  const menuCloseRef = useRef();
  const searchRef = useRef();

  const scrollTop = () => {
    if (typeof window !== 'undefined') {
      return Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop);
    } else {
      return 0;
    }
  };

  let scrollPosition = 0
  const clickMenuBtn = (() => {
    scrollPosition = scrollTop();                   //現在のスクロール位置を取得
    menuRef.current.classList.add('is-open');       //menuにis-openクラス付加
    headerMenuRef.current.classList.add('is-open'); //header__menunにis-openクラス付加
    const bodyRef = document.querySelector('body');
    bodyRef.classList.add('is-fixed');              //bodyにis-fixedクラス付加
    bodyRef.style.top = -scrollPosition;            //bodyを元の位置までスクロール
  });
  const clickMenuCloseBtn = (() => {
    menuCloseRef.current.classList.remove('is-open');   //menu__closeのis-openクラス削除
    menuRef.current.classList.remove('is-open');        //menuのis-openクラス削除
    headerMenuRef.current.classList.remove('is-open');  //header__menuのis-openクラス削除
    headerRef.current.classList.remove('is-open');      //headerのis-openクラス削除
    const bodyRef = document.querySelector('body');
    bodyRef.classList.remove('is-fixed');               //bodyのis-fixedクラス削除
    window.scrollTo(0, scrollPosition);                 //ウインドウを元の位置までスクロール
  });
  const clickSearchBtn = (() => {
    searchRef.current.classList.toggle('is-show');
  });

  return (
    <div className="header__left">
      <div id="header__menu" className="header__menu btn-menu" ref={headerMenuRef} onClick={() => {
        clickMenuBtn()
      }} role="button" data-atlas-trackable="menu"></div>
      <Menu menuRef={menuRef} menuCloseRef={menuCloseRef} clickMenuCloseBtn={clickMenuCloseBtn} />
      <div className="header__search" ref={searchRef}>
        <div id="header__search-btn" className="header__search-btn" onClick={() => {
          clickSearchBtn()
        }}></div>
        <div className="header__search-input">
          <form action="/search">
            <input type="text" name="keyword" placeholder="" maxLength="100" />
            <button className="header__search-input-btn" type="submit" />
          </form>
        </div>
      </div>
    </div>
  );
}

export default HeaderLeft;