import React, { useContext } from "react";
import { gql, useQuery } from "@apollo/client";
import { useTheme } from "styled-components";

import TopicsItem from "../../molecules/TopicsItem";
import Consts from "../../../config/Consts";
import { QueryStringContext } from "../../../contexts/QueryStringContext";

export const GET_ARTICLES = gql`
  query (
    $token: ID,
    $topic_id: ID,
    $information: Boolean,
    $offset:Int,
    $length: Int,
    $width: Int,
    $height: Int,
    $date_staging: String
  ){
    articles(
      token: $token,
      topic_id:$topic_id,
      information: $information,
      offset: $offset,
      length: $length,
      date_staging: $date_staging
    ){
      article {
        target_link
        title
        link_type
        image(width: $width, height: $height, crop:true) {
          src
          etoki
          credit
        }
      }
    }
  }
`;

const TopicsBlock = () => {
  const theme = useTheme();
  const { queryString } = useContext(QueryStringContext);
  const date_staging = typeof queryString.k !== 'undefined' ? queryString.k : ''

  const { loading, error, data } = useQuery(GET_ARTICLES, {
    variables: {
      "token": "TopicsBlock",
      "topic_id": Consts.ID.TOPICS,
      "information": true,
      "offset": 0,
      "length": 3,
      "width": 624,
      "height": 234,
      "date_staging": date_staging
    }
  });
  if (loading) return null;
  if (error) return null;
  if(data.articles.article.length === 0) return null;

  const article_items = data.articles.article.map((article, index) => {
    const props = {
      article: article
    }
    return (
      <React.Fragment key={`promo-${index}`}>
        <TopicsItem {...props} />
      </React.Fragment>
    );
  });

  return (
    <section className={`p-${theme.page}__topics`}
      data-testid="block-name-topics">
      <div className="l-container">
        <h3 className="m-section-headings">TOPICS</h3>
        <ul className="m-article-list-topics" data-testid="list-topics">
          {article_items}
        </ul>
      </div>
    </section>
  )
};

export default TopicsBlock;