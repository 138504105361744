import React, { useContext, useRef, useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import { ThemeProvider } from "styled-components";
import Header from "../../molecules/Header";
import FavoriteBlock from "../../organisms/FavoriteBlock";
import MainTemplate from "../../templates/MainTemplate";
import { UserContext } from "../../../contexts/UserContext";
import Nav from "../../molecules/Nav";
import Consts from "../../../config/Consts";
import useScrollPosition from "../../../hooks/useScrollPosition";
import addslashes from "../../../utils/AddSlashes";

const CLIPPING_ARTICLE = gql`
  mutation (
    $token: ID,
    $mode: Int,
    $user_id: ID,
    $article_id: ID,
    $remove_article_ids: [ID]
  ) {
    user_clipping(
      token: $token,
      mode: $mode,
      user_id: $user_id,
      article_id: $article_id,
      remove_article_ids: $remove_article_ids
    ) {
      code
      result
    }
  }
`;

const FavoritePage = ({post_client}) => {
  const { userInfo } = useContext(UserContext);
  const scrollPosition = useScrollPosition();
  const chkAllRef = useRef();
  const articleRef = useRef();
  const buttonRef = useRef();

  const [clip] = useMutation(CLIPPING_ARTICLE, { client: post_client })

  useEffect(() => {
    if(userInfo.set === 1) {
      if(userInfo.status !== 2) {
        window.location.href = Consts.COMMON.BASE_URL
      }
    }
    if (window.innerHeight - buttonRef.current.getBoundingClientRect().height <= articleRef.current.getBoundingClientRect().top+articleRef.current.getBoundingClientRect().height) {
      //is-fixedクラス付加
      buttonRef.current.classList.add('is-fixed');
    } else {
      //is-fixedクラス削除
      buttonRef.current.classList.remove('is-fixed');
    }
  }, [userInfo, scrollPosition])

  const label = 'お気に入り記事'
  const title = label + '｜THE NIKKEI MAGAZINE｜日本経済新聞社'
  const description = ''
  const analyticsVars = 'var analyticsVars = {title:"' + addslashes(title) + '"};';
  const helmet = [
    <title key='h-title'>{title}</title>,
    <meta key='h-meta-1' name="robots" content="noindex" />,
    <meta key='h-meta-2' name="description" content={description} />,
    <meta key='h-meta-3' property="og:title" content={title} />,
    <meta key='h-meta-4' property="og:description" content={description} />,
    <script key='h-script-1' type="text/javascript">{analyticsVars}</script>
  ]

  const mode = 'default';
  const page = 'favorite';
  const adpagetype = 'ARTICLE';

  return (
    <ThemeProvider theme={{ mode, page, adpagetype }}>
      <MainTemplate
        helmet={helmet}
        header={
          <Header isTop={true} post_client={post_client} />
        }
        main={
          <>
            <Nav />
            <div className={`p-${page}__article l-article`} ref={articleRef} >
              <div className="l-container">
                <h1 className={`p-${page}__headings`}>{label}</h1>
                <FavoriteBlock post_client={post_client} />
              </div>
            </div>
            <div className={`p-${page}__btn`} ref={buttonRef}>
              <div className={`p-${page}__btn-delete`} onClick={() => {
                if (userInfo.id) {
                  const delete_articles = []
                  document.querySelectorAll('.p-favorite__article-checkbox-input')
                    .forEach((ref) => {
                      if(ref.checked) {
                        delete_articles.push(ref.getAttribute('id'));
                      }
                    });
                  if(delete_articles.length > 0) {
                    const variables = {
                      token: "FavoritePage",
                      mode: 2,
                      user_id: userInfo.id,
                      remove_article_ids: delete_articles
                    }
                    clip({ variables: variables }).then((val) => {
                      if (val.data.user_clipping && val.data.user_clipping.code === -1) {
                        // has error
                      }
                      window.location.reload();
                    }).catch((e) => {
                      // has exception
                    });
                  }
                }
              }}>選択記事を削除する</div>
              <div className={`p-${page}__btn-select-all`}>
                <label className={`p-${page}__btn-select-all-label`} htmlFor={`p-${page}__btn-check-all`}>全選択</label>
                <input type="checkbox" id={`p-${page}__btn-check-all`} ref={chkAllRef} onClick={() => {
                  document.querySelectorAll('.p-favorite__article-checkbox-input').forEach((ref) => {
                    ref.checked = chkAllRef.current.checked
                  });
                }}/>
              </div>
            </div>
          </>
        }
      />

    </ThemeProvider>
  )
};

export default FavoritePage;