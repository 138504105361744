import React from 'react';
import ReactDOM from 'react-dom/client';
import queryString from 'query-string';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { UserProvider } from './contexts/UserContext';
import { QueryStringProvider } from './contexts/QueryStringContext';

const client = new ApolloClient({
  link: new HttpLink({
    uri: '/graphql',
    fetchOptions: {
      method: "GET",
      mode: 'no-cors',
    },
  }),
  cache: new InMemoryCache().restore(window.__STATE__)
});

const post_client = new ApolloClient({
  link: new HttpLink({
    uri: '/graphql/api',
    fetch: fetch
  }),
  cache: new InMemoryCache()
});

let userInfo = {id:'', status:0, sex:0, pathname:'/'};
const qs = queryString.parse(window.location.search);
const pathname = window.location.pathname;
const helmetContext = {};
const container = document.getElementById('root');
ReactDOM.hydrateRoot(container, 
  <React.StrictMode>
    <HelmetProvider context={helmetContext}>
      <ApolloProvider client={client}>
        <QueryStringProvider qs={qs} pathname={pathname}>
          <UserProvider userInfo={userInfo} >
            <BrowserRouter>
              <App 
                post_client={post_client}
              />
            </BrowserRouter>
          </UserProvider>
        </QueryStringProvider>
      </ApolloProvider>
    </HelmetProvider>
  </React.StrictMode>
);
// const root = ReactDOM.createRoot(container);
// root.render(
//   <React.StrictMode>
//     <HelmetProvider context={helmetContext}>
//       <ApolloProvider client={client}>
//         <BrowserRouter>
//           <App />
//         </BrowserRouter>
//       </ApolloProvider>
//     </HelmetProvider>
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
