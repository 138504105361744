import React from 'react';

const ArticleA = ({
  article,
  ga_category,
  ga_action,
  ga_label,
  children,
  onClick = () => { },
  ...props
}) => {
  return (
    <a
      href={article.target_link}
      {...(ga_category !== undefined && ga_action !== undefined && ga_label !== undefined) ?
        {
          onClick: (e) => {
            onClick(e);
          }
        } : { onClick: onClick }
      }
      {...((article.direct_move_flag === true && article.link_type === 3) ?
        { target: "_blank", rel: "noopener" } : {})}
      {...props}
    >
      {children}
    </a>
  )
};

export default ArticleA;