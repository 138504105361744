const adid = {
  PC: {
    TOP: {
      BILLBOARD: {
        SECTION: '11516',
        SLOT: '16664'
      },
      RECTANGLE1: {
        SECTION: '11517',
        SLOT: '16665'
      },
      RECTANGLE2: {
        SECTION: '11518',
        SLOT: '16666'
      },
      INREAD: {
        SECTION: '11519',
        SLOT: '16667'
      },
      INFEED: {
        SECTION: '11520',
        SLOT: '16668'
      }
    },
    ARTICLE: {
      BILLBOARD: {
        SECTION: '11526',
        SLOT: '16674'
      },
      RECTANGLE1: {
        SECTION: '11527',
        SLOT: '16675'
      },
      RECTANGLE2: {
        SECTION: '11528',
        SLOT: '16676'
      },
      RECTANGLE3: {
        SECTION: '11796',
        SLOT: '16873'
      },
      INREAD: {
        SECTION: '11529',
        SLOT: '16677'
      },
      INFEED: {
        SECTION: '11530',
        SLOT: '16678'
      }
    }
  },
  SP: {
    TOP: {
      BILLBOARD: {
        SECTION: '11521',
        SLOT: '16669'
      },
      RECTANGLE1: {
        SECTION: '11522',
        SLOT: '16670'
      },
      RECTANGLE2: {
        SECTION: '11523',
        SLOT: '16671'
      },
      INREAD: {
        SECTION: '11524',
        SLOT: '16672'
      },
      INFEED: {
        SECTION: '11525',
        SLOT: '16673'
      }
    },
    ARTICLE: {
      BILLBOARD: {
        SECTION: '11531',
        SLOT: '16679'
      },
      RECTANGLE1: {
        SECTION: '11532',
        SLOT: '16680'
      },
      RECTANGLE2: {
        SECTION: '11533',
        SLOT: '16681'
      },
      RECTANGLE3: {
        SECTION: '11797',
        SLOT: '16874'
      },
      INREAD: {
        SECTION: '11534',
        SLOT: '16682'
      },
      INFEED: {
        SECTION: '11535',
        SLOT: '16683'
      },
      SCROLLER: {
        SECTION: '11536',
        SLOT: '16684'
      }
    }
  }
}
export default adid;