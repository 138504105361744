import React, { useContext } from "react";
import { useTheme } from "styled-components";

import FavoriteBtn from '../../atoms/FavoriteBtn';
import SimpleA from "../../atoms/SimpleA";
import { lm_substr } from "../../../utils/StrUtil";
import Consts from "../../../config/Consts";
import { QueryStringContext } from "../../../contexts/QueryStringContext";

const ArticleShare = ({
  post_client, article_detail
}) => {
  const theme = useTheme();
  const { pathname } = useContext(QueryStringContext);

  const url = Consts.COMMON.BASE_URL + pathname
  const title = encodeURIComponent(lm_substr(article_detail.article_data.sub_title_1, 85, Consts.LIMIT.THREE_DOTS)) + ' - THE NIKKEI MAGAZINE'
  const facebook_url = 'https://www.facebook.com/sharer.php?t=' + title + '&u=' + url
  const twitter_url = 'https://twitter.com/intent/tweet?text=' + title + '&url=' + url + '&related=NIKKEI_MAGAZINE'
  return (
    <div className={`p-${theme.page}__share`}
      data-testid="block-name-share">
      <p className={`p-${theme.page}__share-headings`}>SHARE</p>
      <ul className={`p-${theme.page}__share-list`}>
        <li className={`p-${theme.page}__share-list-item u-facebook`}>
          <div
            className={`p-${theme.page}__share-list-item-icon`}
            onClick={(e) => {
            window.open(facebook_url, 'FBwindow', 'width=550, height=450, menubar=no, toolbar=no, scrollbars=yes');
            e.preventDefault();
          }}></div>
        </li>
        <li className={`p-${theme.page}__share-list-item u-twitter`}>
          <div
            className={`p-${theme.page}__share-list-item-icon`}
            onClick={(e) => {
            window.open(twitter_url, 'TWwindow', 'width=550, height=450, menubar=no, toolbar=no, scrollbars=yes');
            e.preventDefault();
          }}></div>
        </li>
        <FavoriteBtn post_client={post_client} article_id={article_detail.article_data.article_id} is_top={false} />
      </ul>
    </div>
  )
};

export default ArticleShare;