import React from "react";
import { useTheme } from "styled-components";

import ArticleA from "../../atoms/ArticleA";

const FavoriteItem = ({
  article, number
}) => {
  const theme = useTheme();
  const checkNo = "check" + ( '00' + number ).slice( -2 );

  const tags = article.topics.map((topic, index) => {
    return (
      <li key={`tag-f-${checkNo}-${theme.page}-${index}`} className="m-tag__item">{`#${topic.label}`}</li>
    );
  });

  return (
    <li className="m-article-list-3column__item">
      <div className={`p-${theme.page}__article-checkbox`}>
        <label className={`p-${theme.page}__article-checkbox-label`} htmlFor={article.article_id}></label>
        <input type="checkbox" className={`p-${theme.page}__article-checkbox-input`} id={article.article_id} />
      </div>
      <ArticleA
        article={article}
      >
        <div className="m-article-list-3column__image">
          <img src={article.image.src} alt="" />
        </div>
        <div className="m-article-list-3column__text">
          <p className="m-article-list-3column__category">{article.wappen}</p>
          <p className="m-article-list-3column__headings">{article.title}</p>
          <div className="m-tag">
            <ul className="m-tag__list">
              {tags}
            </ul>
          </div>
        </div>
      </ArticleA>
    </li>
  );
};

export default FavoriteItem;