import React from "react";

import htmlEntities from '../../../utils/HtmlEntities';

const AdBanner = ({
  html
}) => {
  if(!html) return null;
  return (
    <li className="m-banner-list__item" dangerouslySetInnerHTML={{ __html: htmlEntities(html) }} />
  )
};

export default AdBanner;