import React from "react";
import { useTheme } from "styled-components";

import Slider from '../Slider';
import PushList from '../PushList';
import Consts from "../../../config/Consts";

const PushBlock = () => {
  const theme = useTheme();

  return (
    <section className={`p-${theme.page}__push`}>
      <div className="l-container">
        <h2></h2>
        <Slider topic_id={Consts.ID.SLIDER} />
        <PushList topic_id={Consts.ID.PUSHLIST} />
      </div>
    </section>
  );
}

export default PushBlock;