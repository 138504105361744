import React, { useContext } from "react";
import { gql, useQuery } from "@apollo/client";
import { useTheme } from "styled-components";

import NewsItem from "../../molecules/NewsItem";
import { QueryStringContext } from "../../../contexts/QueryStringContext";

export const GET_INFORMATION = gql`
  query (
    $token: ID,
    $topic_id: ID,
    $date_staging: String,
  ) {
    notice(
      token: $token,
      topic_id: $topic_id,
      date_staging: $date_staging,
    ) {
      article {
        display_time
        sub_title
        link_url
        link_type
        popup_flag
      }
    }
  }
`;

const NewsBlock = ({
  topic_id
}) => {
  const theme = useTheme();
  const { queryString } = useContext(QueryStringContext);
  const date_staging = typeof queryString.k !== 'undefined' ? queryString.k : ''

  const { loading, error, data } = useQuery(GET_INFORMATION, {
    variables: {
      "token": "NewsBlock",
      "topic_id": topic_id,
      "date_staging": date_staging
    }
  });

  if (loading) return null;
  if (error) return null;
  if(data.notice.article.length === 0) return null;

  const news_items = data.notice.article.map((notice, index) => {
    const props = {
      notice: notice
    }
    return (
      <React.Fragment key={`news-${index}`}>
        <NewsItem  {...props} />
      </React.Fragment>
    );
  });
  if(news_items.length === 0) return null;

  return (
    <section className={`p-${theme.page}__news`}
      data-testid="block-name-newsblock">
      <div className="l-container">
        <h3 className="m-section-headings">NEWS</h3>
        <ul className="m-news-list">
          {news_items}
        </ul>
      </div>
    </section>
  );
};

export default NewsBlock;