import React, { useContext, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom';
import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';

import GlobalStyle from './styles/GlobalStyle';
import TopPage from './components/pages/TopPage';
import NoticePage from './components/pages/NoticePage';
import NewsPage from './components/pages/NewsPage';
import TaglistPage from './components/pages/TaglistPage';
import ArticleIndexPage from './components/pages/ArticleIndexPage';
import ContentsPage from './components/pages/ContentsPage';
import FavoritePage from './components/pages/FavoritePage';
import TagPage from './components/pages/TagPage';
import SearchPage from './components/pages/SearchPage';
import { UserContext } from "./contexts/UserContext";
import { reqServerInfo, generateNikkeiIdMemberLoginUrl } from './utils/Authentication';
import Consts from './config/Consts';
import InfoPage from './components/pages/InfoPage';

const App = (props) => {
  const req = props.req;
  const { userInfo, setUserInfo } = useContext(UserContext);
  const ui = JSON.parse(JSON.stringify(userInfo)); //deepcopy

  useEffect(() => {
    ui.set = 1
    let nid_authenticated = false;
    let nid_autologin = false;
    if(document.cookie) {
      const cookies = document.cookie.split('; ');
      cookies.forEach(function(item) {
        if (item.indexOf(Consts.REACT.NID_AUTHENTICATED+'=') !== -1) {
          nid_authenticated = true
        } else if (item.indexOf(Consts.REACT.NID_AUTOLOGIN+'=') !== -1) {
          nid_autologin = true
        }
      });
    }
    if(nid_authenticated || nid_autologin) {
      (async() => {
        const info = await reqServerInfo();
        if(info && info.code === 0) {
          if(nid_autologin && info.status === 0) {
            // 未ログインだが、オートログイン状態の場合
            window.location.href = generateNikkeiIdMemberLoginUrl(window.location.pathname, queryString.parse(window.location.search), uuidv4())
          } else {
            ui.id = info.user;
            ui.status = info.status;
            ui.sex = parseInt(info.sex,10);
            ui.pathname = info.pathname;
          }
        }
        setUserInfo(ui);
      })()
    } else {
      setUserInfo(ui);
    }
  }, []);

  return (
    <>
      <GlobalStyle />
      <Routes>
        <Route path="/" element={<TopPage {...props} req={req} />} />
        <Route path="/notification" element={<NoticePage {...props} req={req} />} />
        <Route path="/taglist" element={<TaglistPage {...props} req={req} />} />
        <Route path="/article/:article_id" element={<ContentsPage {...props} req={req} />} />
        <Route path="/favorite" element={<FavoritePage {...props} req={req} />} />
        <Route path="/news/:article_id" element={<NewsPage {...props} req={req} />} />
        <Route path="/notification/:article_id" element={<NewsPage {...props} req={req} />} />
        <Route path="/tag/:topic_id" element={<TagPage {...props} req={req} />} />
        <Route path="/search" element={<SearchPage {...props} req={req} />} />
        <Route path="/info/:page_name" element={<InfoPage {...props} req={req} />} />
        <Route path="/:category" element={<ArticleIndexPage {...props} req={req} />} />
        <Route path="/:category/:topic_id" element={<ArticleIndexPage {...props} req={req} />} />
      </Routes>
    </>
  )
}

export default App;
