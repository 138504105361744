import React, { useContext } from "react";
import { useTheme } from "styled-components";
import query_string from 'query-string';

import SimpleA from '../../atoms/SimpleA';
import { QueryStringContext } from "../../../contexts/QueryStringContext";

const Navigation = ({
  article_data
}) => {
  const theme = useTheme();
  const { pathname, queryString } = useContext(QueryStringContext);
  const qs = JSON.parse(JSON.stringify(queryString)); //deepcopy

  const total_page = parseInt(article_data.total_page, 10)
  const page_number = parseInt(article_data.page_number, 10)

  const getLink = (i) => {
    if (i === 1) {
      delete qs.page
    } else {
      qs.page = i;
    }
    return pathname + (Object.keys(qs).length !== 0 ? '?' + query_string.stringify(qs) : '');
  }
  const getTag = (i, page) => {
    if(i === page) {
      return (<li className={`p-${theme.page}__pagenation-item is-active`} key={`npi-${i}`}><SimpleA href={getLink(i)}>{i}</SimpleA></li>)
    } else {
      return (<li className={`p-${theme.page}__pagenation-item`} key={`npi-${i}`}><SimpleA href={getLink(i)}>{i}</SimpleA></li>)
    }
  }
  const pagenation_item = []
  if(total_page <= 7) {
    // 全頁が表示できる
    for(let page=1; page<=total_page; page++) {
      pagenation_item.push(getTag(page, page_number))
    }
  } else {
    if(page_number < 4) {
      // ページが前半にある
      for(let page=1; page<=4; page++) {
        pagenation_item.push(getTag(page, page_number))
      }
      pagenation_item.push(<li className={`p-${theme.page}__pagenation-item`} key={`npi-s0`}><span>...</span></li>)
      for(let page=total_page-1; page<=total_page; page++) {
        pagenation_item.push(getTag(page, page_number))
      }
    } else if(page_number <= total_page - 3) {
      // ページが中間にある場合
      pagenation_item.push(getTag(1, page_number))
      pagenation_item.push(<li className={`p-${theme.page}__pagenation-item`} key={`npi-s0`}><span>...</span></li>)
      for(let page=page_number-1; page<=page_number+1; page++) {
        pagenation_item.push(getTag(page, page_number))
      }
      pagenation_item.push(<li className={`p-${theme.page}__pagenation-item`} key={`npi-s1`}><span>...</span></li>)
      pagenation_item.push(getTag(total_page, page_number))
    } else {
      // ページが後半にある
      for(let page=1; page<=2; page++) {
        pagenation_item.push(getTag(page, page_number))
      }
      pagenation_item.push(<li className={`p-${theme.page}__pagenation-item`} key={`npi-s0`}><span>...</span></li>)
      for(let page=total_page-3; page<=total_page; page++) {
        pagenation_item.push(getTag(page, page_number))
      }
    }
  }

  return (
    <div className={`p-${theme.page}__page-navigation`}>
      {page_number < total_page &&
        <SimpleA href={getLink(page_number+1)} className={`p-${theme.page}__page-navigation-link`}>{article_data.article_next_title}</SimpleA>
      }
      <ul className={`p-${theme.page}__pagenation-list`}>
        {page_number > 1 &&
          <li className={`p-${theme.page}__pagenation-item u-previous`}><SimpleA href={getLink(page_number-1)}>前へ</SimpleA></li>
        }
        {pagenation_item}
        {page_number < total_page &&
          <li className={`p-${theme.page}__pagenation-item u-next`}><SimpleA href={getLink(page_number+1)}>次へ</SimpleA></li>
        }
      </ul>
    </div>
  )
};

export default Navigation;