import React from "react";
import Consts from "../../../config/Consts";
import SimpleA from "../../atoms/SimpleA";
import PageTopLink from "../../molecules/PageTopLink";
import ShareBlock from "../../molecules/ShareBlock";

const FooterBlock = ({
  pr = false
}) => {
  return (
    <>
      {/* footer */}
      <footer id="footer" className="footer">
        {!pr &&
          <>
            <div className="footer__logo">
              <SimpleA href="/"><img src="/images/common/footer_logo@2x.png" alt="THE NIKKEI MAGAZINE" /></SimpleA>
            </div>

            <div className="footer__share">
              <ShareBlock />
            </div>
          </>
        }

        <div className="footer__link">
          <ul className="footer__link-list">
            <li className="footer__link-item"><SimpleA href={Consts.LINK.ABOUT_NIKKEI_URL}>このサイトについて</SimpleA></li>
            <li className="footer__link-item"><SimpleA href={Consts.LINK.SITE_EXEMPTION_URL} target="_blank" rel="noopener">免責</SimpleA></li>
            <li className="footer__link-item"><SimpleA href={Consts.LINK.SITE_COPYRIGHT_URL} target="_blank" rel="noopener">著作権</SimpleA></li>
            <li className="footer__link-item"><SimpleA href={Consts.LINK.LINK_POLICY_URL} target="_blank" rel="noopener">リンクポリシー</SimpleA></li>
            <li className="footer__link-item"><SimpleA href={Consts.LINK.COOKIE_POLICY_URL} target="_blank" rel="noopener">クッキーポリシー</SimpleA></li>
            <li className="footer__link-item"><SimpleA href={Consts.LINK.COOKIE_OPTOUT_URL} target="_blank" rel="noopener">外部送信</SimpleA></li>
            <li className="footer__link-item"><SimpleA href={Consts.LINK.INFO_TOS_URL}>利用規約</SimpleA></li>
            <li className="footer__link-item"><SimpleA href={Consts.LINK.ABOUT_PRIVACY_URL} target="_blank" rel="noopener">個人情報</SimpleA></li>
            <li className="footer__link-item"><SimpleA href={Consts.LINK.ABOUT_MEDIA_URL} target="_blank" rel="noopener">Web広告ガイド</SimpleA></li>
            <li className="footer__link-item"><SimpleA href={Consts.LINK.ABOUT_HELP_URL} target="_blank" rel="noopener">ヘルプセンター</SimpleA></li>
            <li className="footer__link-item"><SimpleA href={Consts.LINK.ABOUT_ANSWERS_URL} target="_blank" rel="noopener">お問い合わせ</SimpleA></li>
          </ul>
        </div>

      </footer>
      {/* /footer */}

      {!pr &&
        <PageTopLink />
      }
    </>
  )
};

export default FooterBlock;