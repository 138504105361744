import React from "react";
import { useTheme } from "styled-components";

import SimpleA from '../../atoms/SimpleA';

const NewsItem = ({
  notice
}) => {
  const theme = useTheme();
  return (
    <>
      <li className="m-news-list__item">
        <SimpleA
          href={notice.link_url}
          {...(theme.page === 'index') ?
            { 'data-atlas-trackable': 'top_news' } : {}
          }
          {...(notice.link_type === '2' && notice.popup_flag === '1') ?
            { target: "_blank", rel: "noopener" } : {}
          }
        >
          <p className="m-news-list__date">{notice.display_time}</p>
          <p className="m-news-list__lead">{notice.sub_title}</p>
        </SimpleA>
      </li>
    </>
  );
}

export default NewsItem;