import React, { useState, useEffect, useCallback, useRef } from "react";
import PropTypes from 'prop-types';

import SimpleA from '../../atoms/SimpleA';
import useIsVisible from "../../../hooks/useIsVisible";

const Article3ColumnInfeed = ({
  adid, index, page_index, device
}) => {
  const container = useRef();
  const isVisible = useIsVisible(container, {once: true});
  const [article, setArticle] = useState(null);
  useEffect(() => {
    if(isVisible) {
      loadAd();
    }
  }, [isVisible]);
  const loadAd = useCallback(() => {
    if (adid) {
      if (typeof TagProvider !== "undefined") {
        try {
          /*global TagProvider */
          TagProvider.requestAds({
            sectionId: adid.SECTION,
            dmp: true, sync: false,
            viewable: { slot: adid.SLOT, percent: 0.1, reload: 1 },
            pageIdModifier: `${adid.SLOT}_${adid.SECTION}_p${page_index}`,
            instance: 'q' + index,
            handler: function (divid) {
              return function (data) {
                if (data[0].creatives.length === 0) {
                  setArticle({noad: true})
                  return
                }
                const creative = data[0].creatives[0];
                const ad = {
                  title: creative.header,
                  wappen: "PR",
                  subchannel_name: "PR",
                  sponsor: creative.body,
                  target_link: creative.image_click_url,
                  image: {
                    src: creative.image_url,
                    credit: "",
                    etoki: "",
                  },
                  link_type: 1,
                  direct_move_flag: true,
                  ad: true,
                  trackingcode: creative.key_values.imp_tracking_tags_code ? creative.key_values.imp_tracking_tags_code : null
                }
                setArticle(ad);
              }
            }(`${adid.SLOT}_${adid.SECTION}_q${index}`)
            , format: 'jsonp_raw'
          });
        } catch (err) {
          console.log('i', err);
        }
      }
    }
  })

  if (article !== null) {
    if(article.noad) {return <></>}
    return (
      <li className="m-article-list-3column__item u-infeed">
        <SimpleA href={article.target_link} target="_blank" rel="noopener">
          <div className="m-article-list-3column__image">
            <img src={article.image.src} alt="" />
          </div>
          <div className="m-article-list-3column__text">
            <p className="m-article-list__pr">{article.wappen}</p>
            <h3 className="m-article-list-3column__headings">{article.title}</h3>
            <p className="m-article-list__company">{article.sponsor}</p>
          </div>
        </SimpleA>
        {article.trackingcode &&
          <p style={{height: '0px'}} dangerouslySetInnerHTML={{ __html: article.trackingcode }}/>
        }
      </li>
    )
  } else {
    return (
      <div className={`is-${device}`} ref={container} >
        {isVisible &&
          <div id={`${adid.SLOT}_${adid.SECTION}_q${index}`} />
        }
      </div>
    )
  }
};

Article3ColumnInfeed.propTypes = {
  adid: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  page_index: PropTypes.number.isRequired,
  device: PropTypes.string.isRequired
}

export default Article3ColumnInfeed;