import React from "react";

import SimpleA from '../../atoms/SimpleA';

const PromotionItem = ({
  article
}) => {

  return (
    <li className="m-article-list-5column__item">
      <SimpleA
        href={article.target_link}
        data-atlas-trackable='top_promotion'
        target={article.direct_move_flag === true && article.link_type === 3 ? '_blank' : ''}
      >
        <div className="m-article-list-5column__image">
          <img src={article.image.src} alt="" />
        </div>
        <div className="m-article-list-5column__text">
          <p className="m-article-list-5column__headings">{article.title}</p>
          <p className="m-article-list__company">{article.sponsor}</p>
        </div>
      </SimpleA>
    </li>
  );
}

export default PromotionItem;