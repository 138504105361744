import React from "react";

import ArticleA from "../../atoms/ArticleA";

const Article2ColumnItem = ({
  page, article, itemIndex
}) => {
  const tags = article.topics.map((topic, index) => {
    return (
      <li key={`tag-a2c-${page}-${index}`} className="m-tag__item">{`#${topic.label}`}</li>
    );
  });

  let atlas_name = "";
  switch(page) {
    case 'article':       // 記事一覧
      atlas_name = 'list_new_' + itemIndex;
      break;
    case 'search':        // 検索
      atlas_name = 'list_new_' + itemIndex;
      break;
    case 'tag':           // タグ記事一覧
      atlas_name = 'list_new_' + itemIndex;
      break;
  }

  return (
    <li className="m-article-list-2column__item">
      <ArticleA
        article={article}
        {...(atlas_name.length > 0) ?
          { "data-atlas-trackable": atlas_name } : {}
        }
      >
        <div className="m-article-list-2column__image">
          <img src={article.image.src} alt="" />
        </div>
        <div className="m-article-list-2column__text">
          <p className="m-article-list-2column__category">{article.wappen}</p>
          <p className="m-article-list-2column__headings">{article.title}</p>
          <div className="m-tag">
            <ul className="m-tag__list">
              {tags}
            </ul>
          </div>
          <p className="m-article-list-2column__date">{article.display_time ? article.display_time : ''}</p>
        </div>
      </ArticleA>
    </li>
  )
};

export default Article2ColumnItem;