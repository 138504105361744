import React, { useEffect, useCallback, useRef } from "react";
import PropTypes from 'prop-types';
import useIsVisible from "../../../hooks/useIsVisible";

const AdInread = ({
  adid,
  noad = () => { }, // 広告がないときに呼ばれる
  viewable = true, // viewable指定 default on
  device,
  ext = false
}) => {
  const adDiv = useRef();
  const adDivInread = useRef();
  const container = useRef();
  const isVisible = useIsVisible(container, {once: true});

  useEffect(() => {
    if(isVisible) {
      loadAd();
      showAd();
    }
  }, [isVisible]);
  const loadAd = useCallback(() => {
    if (adid) {
      if (typeof TagProvider !== "undefined") {
        try {
          const options = viewable === false ? {
            sectionId: adid.SECTION,
            dmp: true,
            sync: false
          } :
            {
              sectionId: adid.SECTION,
              dmp: true,
              sync: false,
              viewable: { slot: adid.SLOT, percent: 0.1, reload: 1 }
              // handler: (a) => {
              //   /*global process_tag_provider_response_11516 */
              //   // process_tag_provider_response_11516(a);
              //   // TagProvider.displayAd(`${adid.SLOT}_${adid.SECTION}`);
              //   console.log('hander', a)
              //   // if(a.length < 0) {
              //   //   section.current.remove();
              //   // }
              //   return a;
              // }
            };
          /*global TagProvider */
          TagProvider.requestAds(options);
        } catch (err) {
        }

        try {
          TagProvider.displayAd(`${adid.SLOT}_${adid.SECTION}`);
        } catch (err) {
        }
      }
    }
  });
  const showAd = () => {
    const listener = () => {
      setTimeout(()=> {
        // iframe で表示されることが前提
        const ad = adDivInread.current.querySelector("iframe");
        // 広告のiframeがない
        // または広告の高さが0なら広告がないとみなす
        if (ad === null
          || parseInt(ad.style.height) === 0) {
          if(adDivInread.current.id === "ADINREAD_SP") {
            adDivInread.current.classList.remove('m-ad-inread-margin-bottom')
          }
          noad.apply();
          removeListner();
        }
      },1000)

    }
    if(adDivInread.current.id === "ADINREAD_SP") {
      adDivInread.current.classList.add('m-ad-inread-margin-bottom')
    }
    // MutationObserverのインスタンスを作成
    let observer = new MutationObserver(function(mutationsList, observer) {
      for(let mutation of mutationsList) {
        if (mutation.type === 'childList' || mutation.type === 'subtree' || mutation.type === 'attributes') {
          listener();
        }
      }
    });
    const removeListner = () => {
      // 監視の停止 (必要に応じて)
      observer.disconnect();
    }
    // 監視オプション
    let config = { childList: true, subtree: true, attributes: true };
    // 監視を開始
    observer.observe(adDiv.current, config);
  }

  if (adid.SLOT.length === 0) return null;

  let pcInreadId = "ADINREAD";
  if (isVisible && typeof window !== "undefined" && device === "pc") {
    if (1016 > window.top.innerWidth && window.top.innerWidth >= 768) {
      pcInreadId = "ADINREAD_M";
    }
  }

  let ext_style = ""
  if(ext) {
    ext_style = " m-ad-inread"
  }

  return (
    <div ref={container} className={`is-${device}${ext_style}`}
      data-testid={`block-name-${adid.SLOT}_${adid.SECTION}`}>
      {isVisible &&
        <>
          <div id={`${adid.SLOT}_${adid.SECTION}`} ref={adDiv}/>
          {device === "pc" &&
            <div id={pcInreadId} ref={adDivInread}></div>
          }
          {device === "sp" &&
            <div id="ADINREAD_SP" ref={adDivInread}></div>
          }
        </>
      }
    </div>
  );
}

AdInread.propTypes = {
  adid: PropTypes.object.isRequired,
  noad: PropTypes.func,
  viewable: PropTypes.bool,
  device: PropTypes.string.isRequired
}

export default AdInread;