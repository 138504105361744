import React from "react";
import { useTheme } from "styled-components";

import htmlEntities from '../../../utils/HtmlEntities';
import ScriptTag from "../../atoms/ScriptTag";
import Navigation from "../Navigation";

const ArticleBody = ({
  article_detail
}) => {
  const theme = useTheme();

  const article_data = article_detail.article_data;
  
  let article_html;
  const srcs = [];
  // if (article_data.link_type !== "1") {
  //   article_html = <div>{article_data.snippet}</div>;
  // } else {
    article_html = article_data.article_text_convert_tag_by_page_list.map((html, index) => {
      if(html.indexOf('<p><h2 ') === 0 && html.endsWith('</h2></p>')) {
        const arr = html.match(/<p><h2 class="([^>]*)">([\s\S]*)<\/h2><\/p>/i)
        return (
          <h2 className={arr[1]} key={`ab-${index}`} dangerouslySetInnerHTML={
            { __html: htmlEntities(arr[2]) }
          } />
        )
      } else if(html.indexOf('<p><h3 ') === 0 && html.endsWith('</h3></p>')) {
        const arr = html.match(/<p><h3 class="([^>]*)">([\s\S]*)<\/h3><\/p>/i)
        return (
          <h3 className={arr[1]} key={`ab-${index}`} dangerouslySetInnerHTML={
            { __html: htmlEntities(arr[2]) }
          } />
        )
      } else if(html.indexOf('<p>') === 0 && html.endsWith('</p>')) {
        return (
          <p className={`p-${theme.page}__article-text`} key={`ab-${index}`} dangerouslySetInnerHTML={
            { __html: htmlEntities(html.substring(3, html.length-4)) }
          } />
        )
      } else if(html.indexOf('<div><ul ') === 0 && html.endsWith('</ul></div>')) {
        const arr = html.match(/<div><ul class="([^>]*)">([\s\S]*)<\/ul><\/div>/i)
        return (
          <ul className={arr[1]} key={`ab-${index}`} dangerouslySetInnerHTML={
            { __html: htmlEntities(arr[2]) }
          } />
        )
      } else if(html.indexOf('<div class=') === 0 && html.endsWith('</div>')) {
        const arr = html.match(/<div( class=[^>]*)>([\s\S]*)<\/div>/i)
        let _body = arr[2]
        const regexp = /(\s((?:class|style))=(('([^'>]*)')|("([^">]*)")|([^\s>]*)))/ig
        let result
        const params = {class:null, style:null}
        while(result = regexp.exec(arr[1])){
          const val = result[5]?result[5]:result[7]
          switch(result[2]) {
            case 'class':
              params.class = val
              break;
            case 'style':
              params.style = val
              break;
            default:
              break;
          }
        }
        if(params.class === 'p-contents__article-movie' || params.class.match(/^video(\d+)_(\d+)$/)) {
          // scriptタグはCSRで読み込む
          _body = _body.replace(/<script[\s\S]*?<\/script>/g, function (match) {
            var src = match.match(/src="(.+?)"/);
            if (src) {
              srcs.push(src[1]);
            }
            return '';
          });
        } else if(params.class === 'clearfix') {
          _body = _body.replace(/<p>/g, '<p class="p-'+theme.page+'__article-text">');
          // scriptタグはCSRで読み込む
          _body = _body.replace(/<script[\s\S]*?<\/script>/g, function (match) {
            var src = match.match(/src="(.+?)"/);
            if (src) {
              srcs.push(src[1]);
            }
            return '';
          });
        }
        return (
          <div className={params.class} key={`ab-${index}`} dangerouslySetInnerHTML={
            { __html: htmlEntities(_body) }
          } />
        )
      } else if(html.indexOf('<div id') === 0 && html.endsWith('</div>')) {
        const arr = html.match(/<div id="([^>]*)">([\s\S]*)<\/div>/i)
        return (
          <div id={arr[1]} key={`ab-${index}`} dangerouslySetInnerHTML={
            { __html: htmlEntities(arr[2]) }
          } />
        )
      } else {
        return (
          <div key={`ab-${index}`} dangerouslySetInnerHTML={
            { __html: htmlEntities(html) }
          } />
        )
      }
    });
  // }

  // 念のため重複除去しタグ出力
  const script_tags = Array.from(new Set(srcs)).map((script, index) => {
    return (<ScriptTag src={script} key={`sc-${index}`} sid={`sct-${index}`} />)
  });

  const promo_html = article_detail.promo_html ? article_detail.promo_html.map((html, index) => {
    return (
      <div key={`ph-${index}`} className="promo-html" dangerouslySetInnerHTML={
        { __html: htmlEntities(html) }
      } />
    )
  }) : '';

  return (
    <div className={`p-${theme.page}__article-contents`}
      data-testid="block-name-article-body">
      <div className="clearfix">
        {article_html}
      </div>
      {script_tags}
      {article_data.link_type === "1" && article_data.page_number === article_data.total_page &&
        promo_html
      }
      {parseInt(article_data.total_page,10) > 1 && 
        <Navigation article_data={article_data} />
      }
    </div>
  )
};

export default ArticleBody;