import React, { useContext } from "react";
import { ThemeProvider } from "styled-components";

import Header from "../../molecules/Header";
import SearchArticleList2ColumnWithSideBarBlock from "../../organisms/SearchArticleList2ColumnWithSideBarBlock";
import RecommendBlock from "../../organisms/RecommendBlock";
import TopicsBlock from "../../organisms/TopicsBlock";
import MainTemplate from "../../templates/MainTemplate";
import { QueryStringContext } from "../../../contexts/QueryStringContext";
import Nav from "../../molecules/Nav";
import addslashes from "../../../utils/AddSlashes";
import Consts from "../../../config/Consts";
import RectangleAd from "../../ads/RectangleAd";
import adid from "../../../config/adid";

const SearchPage = ({post_client}) => {
  const {queryString} = useContext(QueryStringContext);
  const keyword = typeof queryString.keyword !== 'undefined' ? queryString.keyword : '';

  const label = "”"+keyword+"”に関する記事"
  const title = label + '｜THE NIKKEI MAGAZINE｜日本経済新聞社'
  const description = keyword + 'のページ一覧です。' + Consts.COMMON.DESCRIPTION
  const analyticsVars = 'var analyticsVars = {title:"' + addslashes(title) + '"};';
  const helmet = [
    <title key='h-title'>{title}</title>,
    <meta key='h-meta-1' name="robots" content="noindex" />,
    <meta key='h-meta-2' name="description" content={description} />,
    <meta key='h-meta-3' property="og:title" content={title} />,
    <meta key='h-meta-4' property="og:description" content={description} />,
    <link key='h-canonical' rel="canonical" href={`${Consts.COMMON.BASE_URL}/search`} />,
    <script key='h-script-1' type="text/javascript">{analyticsVars}</script>
  ]

  const mode = 'default';
  const page = 'article-index';
  const adpagetype = 'ARTICLE';
  
  return (
    <ThemeProvider theme={{ mode, page, adpagetype }}>
      <MainTemplate
        helmet={helmet}
        header={
          <Header post_client={post_client} />
        }
        main={
          <>
            <Nav />
            <SearchArticleList2ColumnWithSideBarBlock label={label} />
            <TopicsBlock />
            <RectangleAd adid={adid.SP[adpagetype].RECTANGLE2} viewable={true} device="sp" />
            <RecommendBlock />
            <RectangleAd adid={adid.SP[adpagetype].RECTANGLE3} viewable={true} device="sp" />
          </>
        }
      />
    </ThemeProvider>
  )
};

export default SearchPage;