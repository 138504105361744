import React, { useState, useEffect, useRef } from "react";
import { gql, useQuery } from "@apollo/client";
import { useTheme } from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs } from "swiper";

import RankingList from "../RankingList";
import Consts from "../../../config/Consts";

export const GET_RANKING_LIST = gql`
  query (
    $token: ID,
    $length: Int,
    $width: Int,
    $height: Int,
    $exclusion_topic_id: [ID]
  ) {
    ranking_list(
      token: $token
      length: $length,
      exclusion_topic_id: $exclusion_topic_id
    ) {
      ranking {
        rank
        article{
          article_id
          title
          description
          wappen
          subchannel_name
          target_link
          display_time
          sponsor
          is_new
          movie_flag
          link_type
          direct_move_flag
          image(width: $width, height: $height, crop:true) {
            src
            etoki
            credit
          }
        }
      }
      topic_id
    }
    category_list(
      token: $token
    ) {
      label
      topic_id
    }
  }
`;

const RankingBlock = ({
  topic_id
}) => {
  const theme = useTheme();
  const [activeIndex, setActiveIndex] = useState(-1);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const contentsSwiperRef = useRef();
  const ranking_items = {name:[], list:[]};

  useEffect(() => {
    if(contentsSwiperRef && contentsSwiperRef.current && ranking_items.name.length > 1) {
      const contentsSwiper = contentsSwiperRef.current.swiper;
      if(contentsSwiper && !contentsSwiper.destroyed && activeIndex >= 0) {
        contentsSwiper.slideTo(activeIndex);
      }
    }
  }, []);

  const { loading, error, data } = useQuery(GET_RANKING_LIST, {
    variables: {
      "token": "RankingBlock",
      "length": 5,
      "width": 220,
      "height": 146,
      "exclusion_topic_id": Consts.ID.EXCLUSION_RANKING
    }
  });

  if (loading) return null;
  if (error) return null;
  if(data.ranking_list.length === 0) return null;

  let active_pos = 1
  let swiper_pos = 1
  data.ranking_list.forEach((ranking, index) => {
    if(ranking.ranking.length === 0) return null;
    let name = "undefined"
    if(ranking.topic_id === "common") {
      name = "ALL"
    } else {
      for(let category of data.category_list) {
        if(category.topic_id === ranking.topic_id) {
          name = category.label
          break;
        }
      }
    }
    if(name.length === 0) return null;
    ranking_items.name.push(
      <SwiperSlide className={`m-ranking-header-item swiper-slide`} key={`rn-${index}`}>
        {name}
      </SwiperSlide>
    );
    ranking_items.list.push(
      <SwiperSlide key={`rl-${index}`}>
        <RankingList ranking={ranking.ranking} />
      </SwiperSlide>
    );
    if(ranking.topic_id === topic_id) {
      active_pos = swiper_pos
    }
    swiper_pos++
  });
  if(ranking_items.name.length === 0) return null;
  if(activeIndex<0) {
    setActiveIndex(active_pos)
  }

  return (
    <section className={`p-${theme.page}__ranking m-ranking`}
      data-testid="block-name-ranking">
      <h3 className="m-section-headings">RANKING</h3>
      <div className="m-ranking-slider">
        <div className="m-ranking-header-wrapper">
          <Swiper
            slidesPerView={3}
            slideToClickedSlide={true}  // クリックしたらそのアイテムに移動
            navigation={{                // paginationのクラス名
              nextEl: '.m-ranking-header .swiper-button-next',
              prevEl: '.m-ranking-header .swiper-button-prev',
            }}
            modules={[Navigation, Thumbs]}
            watchSlidesProgress
            onSwiper={setThumbsSwiper}
            className="m-ranking-header"
            loop={true}
          >
            <div className="swiper-button-prev"></div>
            <div className="swiper-button-next"></div>
            {ranking_items.name}
          </Swiper>
        </div>
        <Swiper
          thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
          slidesPerView={1}
          modules={[Thumbs]}
          allowTouchMove={false}
          className="m-ranking-contents"
          ref={contentsSwiperRef}
          loop={true}
        >
          {ranking_items.list}
        </Swiper>
      </div>
    </section>
  )
};

export default RankingBlock;