import React, { useEffect, useContext, useState, useRef } from "react";

import { UserContext } from "../../../contexts/UserContext";
import NewsBody from "../../molecules/NewsBody";
import Consts from "../../../config/Consts";

const News = ({
  article_detail
}) => {
  const { userInfo } = useContext(UserContext);
  const [body, setBody] = useState(null);
  const setBodyData = useRef(false);

  useEffect(() => {
    if(!setBodyData.current)
    {
      const chk_article = () => {
        if(userInfo.status === 2) {
          if(userInfo.sex === 1) {
            // 男性に女性向けの記事
            if(article_detail.article_data.operation_tags.indexOf('D106') !== -1) {
              return false
            }
          } else if(userInfo.sex === 2) {
            // 女性に男性向けの記事
            if(article_detail.article_data.operation_tags.indexOf('D105') !== -1) {
              return false
            }
          } else {
            // その他に性別向けの記事
            if(article_detail.article_data.operation_tags.indexOf('D105') !== -1 || 
            article_detail.article_data.operation_tags.indexOf('D106') !== -1 ) {
              return false
            }
          }
        } else {
          // あなたへのお知らせは会員のみの記事
          if(article_detail.article_data.operation_tags.indexOf('D105') !== -1 ||
            article_detail.article_data.operation_tags.indexOf('D106') !== -1 ||
            article_detail.article_data.operation_tags.indexOf('D110') !== -1 )
          {
            return false
          }
        }
        return true
      }
      if(userInfo.set === 1) {
        if(!chk_article()) {
          window.location.href = Consts.COMMON.BASE_URL
        }
      }
      setBody(chk_article() ? <NewsBody article_detail={article_detail} /> : null)
    }
  }, [userInfo])

  // operation_tagsに'D105','D106','D110'が含まれない場合は表示制御なし
  if(!setBodyData.current &&
    article_detail.article_data.operation_tags.indexOf('D105') === -1 &&
    article_detail.article_data.operation_tags.indexOf('D106') === -1 &&
    article_detail.article_data.operation_tags.indexOf('D110') === -1 )
  {
    setBodyData.current = true
    setBody(<NewsBody article_detail={article_detail} />)
  }

  return (
    <>
      {body}
    </>
  )
};

export default News;