import React, { useContext, useEffect, useRef } from 'react';
import HelmetTemplate from '../HelmetTemplate';
import { Helmet } from 'react-helmet-async';
import { useTheme } from 'styled-components';

import "../../../scss/style-pc.scss";
import "../../../scss/style-sp.scss";

import NikkeiHeader from '../../molecules/NikkeiHeader';
import FooterBlock from '../../organisms/FooterBlock';
import NikkeiFooter from '../../molecules/NikkeiFooter';
import { UserContext } from "../../../contexts/UserContext";

const MainTemplate = ({
  helmet = '',
  main = '',
  header = '',
  footer = <FooterBlock />,
  nikkei_footer = <NikkeiFooter />,
  custom_key_param = null
}) => {
  const theme = useTheme();
  const contentsRef = useRef();
  const { userInfo } = useContext(UserContext);

  useEffect(() => {
    // send_ga('send', 'pageview');
    contentsRef.current.classList.remove('u-member');
    contentsRef.current.classList.remove('u-nonmember');
    if(userInfo.status === 2) {
      contentsRef.current.classList.add('u-member');
    } else {
      contentsRef.current.classList.add('u-nonmember');
    }
  });

  return (
    <div className={theme.mode} id="container">
      <HelmetTemplate {...custom_key_param} />
      <Helmet>
        {helmet}
      </Helmet>
      <NikkeiHeader />
      <div className={`p-${theme.page}`} ref={contentsRef}>
        {header}
        <div className="l-main">
          {main}
        </div>
        {footer}
        {nikkei_footer}
      </div>
    </div>
  );
}

export default MainTemplate;