import React from "react";
import { useTheme } from "styled-components";

import ArticleA from "../../atoms/ArticleA";

const PushListItem = ({
  article
}) => {
  const theme = useTheme();

  const tags = article.topics.map((topic, index) => {
    return (
      <li key={`tag-pl-${theme.page}-${index}`} className="m-tag__item">{`#${topic.label}`}</li>
    );
  });

  return (
      <li className={article.is_new ? "m-article-list-3column__item is-new" : "m-article-list-3column__item" }>
        <ArticleA
          article={article}
          data-atlas-trackable='top_banner'
        >
          <div className="m-article-list-3column__image">
            <img src={article.square_image.src} alt="" />
          </div>
          <div className={`p-${theme.page}__push-text`}>
            <p className="m-article-list-3column__category">{article.wappen}</p>
            <h3 className="m-article-list-3column__headings">{article.title}</h3>
            <p className="m-article-list-3column__lead">{article.snippet}</p>
            {tags.length > 0 &&
              <div className="m-tag">
                <ul className="m-tag__list">
                  {tags}
                </ul>
              </div>
            }
          </div>
        </ArticleA>
      </li>
  );
}

export default PushListItem;