import React, { useEffect } from 'react';
import HelmetTemplate from '../HelmetTemplate';
import { Helmet } from 'react-helmet-async';

import "../../../scss/style-pc.scss";
import "../../../scss/style-sp.scss";

import { useTheme } from 'styled-components';

const TuTemplate = ({
  helmet = '',
  main = '',
  custom_key_param = null
}) => {
  const theme = useTheme();
  useEffect(() => {
    // send_ga('send', 'pageview');
  });

  return (
    <div className={`${theme.mode} is-pr`} id="container">
      <HelmetTemplate {...custom_key_param}/>
      <Helmet>
        {helmet}
      </Helmet>
      {main}
    </div>
  );
}

export default TuTemplate;