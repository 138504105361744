import React, { useContext } from "react";
import { gql, useQuery } from "@apollo/client";
import { ThemeProvider } from "styled-components";
import { useParams } from 'react-router-dom';

import { FavoriteProvider } from '../../../contexts/FavoriteContext';
import { QueryStringContext } from "../../../contexts/QueryStringContext";

import Contents from "../../organisms/Contents";
import ContentsAi from "../../organisms/ContentsAi";
import ContentsTu from "../../organisms/ContentsTu";
import ContentsPr from "../../organisms/ContentsPr";
import ContentsAiTu from "../../organisms/ContentsAiTu";
import Consts from "../../../config/Consts";
import htmlEntities from '../../../utils/HtmlEntities';
import { lm_substr } from "../../../utils/StrUtil";
import addslashes from "../../../utils/AddSlashes";

export const GET_ARTICLE_DETAIL = gql`
  query (
    $token: ID,
    $article_id: ID,
    $article_folder_id: ID,
    $page: Int,
    $date_staging: String,
    $top_image_width: Int,
    $top_image_height: Int,
    $top_image_vertical_width: Int,
    $top_image_vertical_height: Int,
    $top_sp_image_width: Int,
    $top_sp_image_height: Int,
    $related_image_width: Int,
    $related_image_height: Int
  ){
    article_detail(
      token: $token,
      article_id: $article_id,
      article_folder_id: $article_folder_id,
      page: $page,
      date_staging: $date_staging
    ) {
      channel_data {
        channel_id
        notice_id
        topic_id
        display {
          ranking
          ad
        }
        custom_html
      }
      top_image_data(width: $top_image_width, height: $top_image_height) {
        src
        etoki
        credit
      }
      top_image_vertical(width: $top_image_vertical_width, height: $top_image_vertical_height) {
        src
        etoki
        credit
      }
      top_image_data_sp: top_image_data(width: $top_sp_image_width, height: $top_sp_image_height) {
        src
        etoki
        credit
      }
      article_data {
        name
        sub_name
        folder_id
        sub_folder_id
        article_id
        article_encoded_id
        article_folder_id
        news_attribute_id
        source
        display_time
        display_time_org
        date_public
        date_modified
        sub_title_detail
        sub_title_sec
        sub_title_1
        sub_title_2
        media_name
        snippet
        snippet2_continue_flg
        article_text_convert_tag_by_page_list
        link_type
        link_info
        # movie_flag
        author
        writer1
        writer2
        writer
        keyword
        article_next_title
        page_number
        total_page
        wappen
        exinfo
        original_url
        id_type
        topic_id
        sub_topic_id
        topics {
          topic_id
          label
        }
        operation_tags
        image_twitter
        tieup_header_html
        tieup_article_html
        category_path
        sub_category_path
        service_category
      }
      related_all_list(width: $related_image_width, height: $related_image_height) {
        sub_title
        link_url
        image_url
        image_etoki
      }
      promo_html
    }
  }
`

const ContentsPage = ({post_client}) => {

  let { article_id } = useParams();
  const { queryString } = useContext(QueryStringContext);
  const article_folder_id = typeof queryString.channel !== 'undefined' ? queryString.channel : '';
  const page_count = (typeof queryString.page !== 'undefined') ? (parseInt(queryString.page, 10) < 1 ? 1 : parseInt(queryString.page, 10)) : 1;
  const date_staging = typeof queryString.k !== 'undefined' ? queryString.k : ''

  const { loading, error, data } = useQuery(GET_ARTICLE_DETAIL, {
    variables: {
      "token": "ContentsPage",
      "article_id": article_id,
      "article_folder_id": article_folder_id,
      "page": page_count,
      "top_image_width": 1992,
      "top_image_height": 1324,
      "top_image_vertical_width": 960,
      "top_image_vertical_height": 1280,
      "top_sp_image_width": 1500,
      "top_sp_image_height": 1500,
      "related_image_width": 220,
      "related_image_height": 146,
      "date_staging": date_staging
    }
  });
  if (loading) return null;
  if (error) return null;
  if (data.article_detail.article_data === null) {
    throw new Error('NOTFOUND');
  }

  const main_contents = () => {
    const article_data = data.article_detail.article_data;
    let title = article_data.sub_title_1
    if(page_count > 1) {
      title += '（' + page_count + 'ページ目）'
    }
    title += '｜THE NIKKEI MAGAZINE'
    let description = lm_substr(
      article_data.snippet.replace(/\r?\n/g, ''),
      Consts.LIMIT.META_ARTICLE_DESCRIPTION_LIMIT,
      Consts.LIMIT.THREE_DOTS,
      article_data.snippet2_continue_flg);
    description = htmlEntities(description, 'encode');
    const tieup_flag = (article_data.operation_tags.indexOf(Consts.ID.OPERATION_TAG_TU) !== -1)
    const rich_tieup_flag = (article_data.operation_tags.indexOf(Consts.ID.OPERATION_TAG_RTU) !== -1)
    let sub_topic_id = ''
    let sub_topic_name = ''
    if(article_data.sub_topic_id) {
      sub_topic_id = article_data.sub_topic_id;
      sub_topic_name = addslashes(article_data.sub_name);
    }
    let operation_tags_str = ''
    if(article_data.operation_tags.length > 0) {
      for(let tag of article_data.operation_tags) {
        operation_tags_str += ',\''+tag+'\''
      }
      operation_tags_str = operation_tags_str.substring(1)
    }
    let tag_id_str = ''
    let tag_name_str = ''
    if(article_data.topics.length > 0) {
      for(let topic of article_data.topics) {
        tag_id_str += ',\''+topic.topic_id+'\''
        tag_name_str += ',\''+addslashes(topic.label)+'\''
      }
      tag_id_str = tag_id_str.substring(1)
      tag_name_str = tag_name_str.substring(1)
    }
    const tu_id_str = addslashes(article_data.writer2)
    let analyticsVars = 'var analyticsVars = {'
      + 'article_id:"' + article_data.article_id + '"'
      + ',topic_id:"' + article_data.topic_id + '"'
      + ',topic_name:"' + addslashes(article_data.name) + '"'
      + ',sub_topic_id:"' + sub_topic_id + '"'
      + ',sub_topic_name:"' + sub_topic_name + '"'
      + ',page:"' + page_count + '/' + article_data.total_page + '"'
      + ',title:"' + addslashes(title) + '"'
      + ',author_name:"' + addslashes(article_data.author) + '"'
      + ',author_name1:"' + addslashes(article_data.writer1) + '"'
      + ',operation_tags:[' + operation_tags_str + ']'
      + ',tag_id:[' + tag_id_str + ']'
      + ',tag_name:[' + tag_name_str + ']'
      + ',tu_id:"' + tu_id_str + '"'
      + '};';
    const helmet = [
      <title key='h-title'>{title}</title>,
      <meta key='h-meta-1' name="description" content={description} />,
      <meta key='h-meta-2' property="og:title" content={title} />,
      <meta key='h-meta-3' property="og:type" content="article" />,
      <meta key='h-meta-4' property="og:description" content={description} />,
      <meta key='h-meta-5' property="og:image" content={article_data.image_twitter}/>,
      <meta key='h-meta-6' name="ps:tuid" content={article_data.writer2}></meta>,
      <script key='h-script-1' type="text/javascript">{analyticsVars}</script>
    ]
    const canonical_url = (article_data.article_encoded_id) ? `${Consts.COMMON.BASE_URL}/article/${article_data.article_encoded_id}` : null;
    if (canonical_url) {
      const canonical_page = (page_count > 1) ? `?page=${page_count}` : '';
      helmet.push(<link key='h-canonical' rel="canonical" href={`${canonical_url}${canonical_page}`} />);
    }

    const custom_key_topics = [article_data.topic_id]
    article_data.topics.forEach((topic) => {
      custom_key_topics.push(topic.topic_id);
    });
    article_data.operation_tags.forEach((tag) => {
      custom_key_topics.push(tag);
    });
    if(article_data.sub_topic_id) {
      custom_key_topics.push(article_data.sub_topic_id)
    }

    const params = {
      article_detail: data.article_detail,
      post_client: post_client,
      helmet: helmet,
      custom_key_topics
    }
    if(article_data.topic_id === Consts.ID.AI) {
      if(tieup_flag) {
        return <ContentsAiTu {...params} />
      } else {
        return <ContentsAi {...params} />
      }
    } else if(tieup_flag) {
      return <ContentsTu {...params} />
    } else if(rich_tieup_flag) {
      return <ContentsPr {...params} />
    } else {
      return <Contents {...params} />
    }
  }

  const mode = 'default';
  const page = 'contents';
  const adpagetype = 'ARTICLE';

  return (
    <ThemeProvider theme={{ mode, page, adpagetype }}>
      <FavoriteProvider favorite={null} >
        {main_contents()}
      </FavoriteProvider>
    </ThemeProvider>
  )
};

export default ContentsPage;