import React from "react";
import Consts from "../../../config/Consts";
import SimpleA from "../../atoms/SimpleA";

const NikkeiHeader = ({
  pr = false
}) => {
  return (
    <div id="nikkei-header" className="brand_white">
      <div className="nikkei-header_inner">
        <div className="nikkei-header_logo">
          <SimpleA href={Consts.LINK.NIKKEI_URL} target="_blank" title="日本経済新聞" rel="noreferrer">
            <img src="/images/common/logo-denshi_white.svg" alt="日本経済新聞" />
          </SimpleA>
        </div>
        {pr &&
          <span className="nikkei-header_aside">
            <img src="/images/common/pr_white.png" width="25" height="16" alt="PR" />
          </span>
        }
      </div>
    </div>
  );
}

export default NikkeiHeader;