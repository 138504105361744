import React, { useContext, useEffect, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { useTheme } from "styled-components";

import { UserContext } from "../../../contexts/UserContext";
import NewsItem from "../../molecules/NewsItem";
import Consts from "../../../config/Consts";

const USER_NOTICE = gql`
  mutation (
    $token: ID,
    $mode: Int,
    $user_id: ID,
    $notice_id: [ID]
  ) {
    user_notice(
      token: $token,
      mode: $mode,
      user_id: $user_id,
      notice_id: $notice_id
    ) {
      code
      result
      article {
        link_url
        link_type
        popup_flag
        display_time
        sub_title
      }
    }
  }
`;

const NoticeBlock = ({
  post_client
}) => {
  const theme = useTheme();
  const { userInfo } = useContext(UserContext);
  const [notice_items, setNoticeItems] = useState(null);
  const [notice] = useMutation(USER_NOTICE, { client: post_client })

  useEffect(() => {
    if(userInfo.set === 1) {
      if(userInfo.status !== 2) {
        window.location.href = Consts.COMMON.BASE_URL
      }
    }
    if(userInfo.status === 2) {
      const variables = {
        token: "NoticeBlock",
        mode: 1,
        user_id: userInfo.id,
        notice_id: Consts.ID.NOTICE_TAG[userInfo.sex]
      }
      notice({ variables: variables }).then((val) => {
        if (val.data.user_notice && val.data.user_notice.code === 0) {
          if (val.data.user_notice.article && val.data.user_notice.article.length > 0) {
            const news_items = val.data.user_notice.article.map((notice, index) => {
              const props = {
                notice: notice
              }
              return (
                <React.Fragment key={`news-${index}`}>
                  <NewsItem  {...props} />
                </React.Fragment>
              );
            });
            setNoticeItems(news_items)
          }
        }
      });
    }
  }, [userInfo, notice, setNoticeItems]);

  return (
    <section className={`p-${theme.page}__news`}>
      <div className="l-container">
        <h3 className="m-section-headings">NEWS</h3>
        <ul className="m-news-list">
          {notice_items}
        </ul>
      </div>
    </section>
  );
};

export default NoticeBlock;