const htmlEntities = (text, proc) => {
  if(text) {
    const entities = [
      ['amp', '&'],
      ['apos', '\''],
      ['lt', '<'],
      ['gt', '>'],
    ];
    for (let i = 0, max = entities.length; i < max; i++) {
      if ('encode' === proc) {
        text = text.replace(new RegExp(entities[i][1], 'g'), "&" + entities[i][0] + ';').replace('"', '&quot;');
      } else {
        text = text.replace('&quot;', '"').replace(new RegExp('&' + entities[i][0] + ';', 'g'), entities[i][1]);
      }
    }
  }
  return text;
};

export default htmlEntities;