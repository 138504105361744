import React, { useContext, useEffect, useRef } from "react";
import { useTheme } from "styled-components";

import TuTemplate from "../../templates/TuTemplate";
import Header from "../../molecules/Header";
import ArticleTop from "../../molecules/ArticleTop";
import NikkeiAiFooter from "../../molecules/NikkeiAiFooter";
import NikkeiHeader from "../../molecules/NikkeiHeader";
import ArticleBody from "../../molecules/ArticleBody";
import ArticleShare from "../../molecules/ArticleShare";
import ArticleTagList from "../../molecules/ArticleTagList";
import RelatedBlock from "../RelatedBlock";
import FooterBlock from "../FooterBlock";
import { UserContext } from "../../../contexts/UserContext";
import Nav from "../../molecules/Nav";
import Consts from "../../../config/Consts";
import SimpleA from "../../atoms/SimpleA";

const ContentsAiTu = ({
  article_detail,
  post_client,
  helmet,
  custom_key_topics
}) => {
  const theme = useTheme();
  const contentsRef = useRef();
  const { userInfo } = useContext(UserContext);

  useEffect(() => {
    contentsRef.current.classList.remove('u-member');
    contentsRef.current.classList.remove('u-nonmember');
    if(userInfo.status === 2) {
      contentsRef.current.classList.add('u-member');
    } else {
      contentsRef.current.classList.add('u-nonmember');
    }
  });

  const custom_key_param = { topics: custom_key_topics, article_id: article_detail.article_data.article_id }
  const related_list = article_detail.related_all_list;
  return (
    <TuTemplate
      custom_key_param={custom_key_param}
      helmet={helmet}
      main={
        <>
          <NikkeiHeader pr={true} />
          <div className={`p-${theme.page}`} ref={contentsRef}>
            <Header post_client={post_client} />
            <Nav />
            <div className="l-main">
              <ArticleTop post_client={post_client} article_detail={article_detail} />
              <section className={`p-${theme.page}__article p-contents__tu`}>
                <div className="l-container">
                  <div className={`p-${theme.page}__article-wrapper`}>
                    <div className={`p-${theme.page}__article-main`}>
                      <ArticleBody article_detail={article_detail} />
                      <RelatedBlock related_list={related_list} />
                      <div className="p-contents__ai">
                        <SimpleA href={Consts.LINK.AI_URL}>
                          <picture>
                            <source media="(max-width: 767px)" srcSet="/images/contents_ai_sp@2x.jpg" />
                            <img src="/images/contents_ai_s@2x.jpg" alt="" />
                          </picture>
                        </SimpleA>
                      </div>
                      <ArticleShare post_client={post_client} article_detail={article_detail} />
                      <ArticleTagList article_data={article_detail.article_data} />
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <FooterBlock />
            <NikkeiAiFooter />
          </div>
        </>
      }
    />
  )
};

export default ContentsAiTu;