import React, { useContext, useRef, useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';

import { generateNikkeiIdMemberLoginUrl, generateNikkeiIdMemberLogoutUrl, reqClearKbn, reqClear } from '../../../utils/Authentication';
import { UserContext } from "../../../contexts/UserContext";
import { QueryStringContext } from "../../../contexts/QueryStringContext";
import NoticeMenu from "../../atoms/NoticeMenu";
import Consts from "../../../config/Consts";
import SimpleA from "../../atoms/SimpleA";

const HeaderRight = ({post_client}) => {
  const { pathname, queryString } = useContext(QueryStringContext);
  const { userInfo } = useContext(UserContext);
  const memberMenuRef = useRef();
  const memberRef = useRef();
  const [button, setButton] = useState(null);

  useEffect(() => {
    const nikkei_btn = () => {
      switch(userInfo.status){
        case 1:
          // 日経IDユーザ
          return(
            <div className="header__nikkei">
              <div id="header__nikkei-btn" className="header__nikkei-btn">日経電子版</div>
              <div id="header__nikkei-menu" className="header__nikkei-menu">
                <ul className="header__nikkei-menu-list">
                  <li className="header__nikkei-menu-item">
                    <SimpleA href={Consts.LINK.NIKKEI_URL} target="_blank" rel="noopener">日経電子版TOP</SimpleA>
                  </li>
                </ul>
                <SimpleA className="header__nikkei-menu-logout" onClick={() => { clickLogout() }}>ログアウト</SimpleA>
              </div>
            </div>
          )
        case 2:
          // 日経マガジン会員
          return(
            <SimpleA href={Consts.LINK.NIKKEI_URL} className="header__nikkei-a-btn" target="_blank" rel="noopener">日経電子版</SimpleA>
          )
        default:
          return(
            <SimpleA href={Consts.LINK.NIKKEI_URL} className="header__nikkei-a-btn" target="_blank" rel="noopener">日経電子版</SimpleA>
          )
      }
    }
    const user_btn = () => {
      switch(userInfo.status){
        case 1:
          // 日経IDユーザ
          return(
            <div className="header__nonmember">
              <SimpleA className="header__registration" onClick={() => { clickRegistration() }}>会員登録</SimpleA>
            </div>
          )
        case 2:
          // 日経マガジン会員
          return(
            <div className="header__member">
              <div id="header__member-btn" className="header__member-btn" ref={memberRef} onClick={() => {
                clickMemberBtn()
              }}>会員メニュー</div>
              <div id="header__member-menu" className="header__member-menu" ref={memberMenuRef}>
                <ul className="header__member-menu-list">
                  <li className="header__member-menu-item">
                    <SimpleA href="/favorite">お気に入りの記事</SimpleA>
                  </li>
                  <NoticeMenu post_client={post_client} id={userInfo.id} />
                  <li className="header__member-menu-item">
                    <SimpleA onClick={() => { clickChange() }}>会員情報確認・変更</SimpleA>
                  </li>
                  <li className="header__member-menu-item">
                    <SimpleA onClick={() => { clickCloseMailmagazine() }}>メルマガ解除</SimpleA>
                  </li>
                </ul>
                <SimpleA className="header__member-menu-logout" onClick={() => { clickLogout() }}>ログアウト</SimpleA>
              </div>
            </div>
          )
        default:
          return(
            <div className="header__nonmember">
              <div className="header__login" onClick={() => {
                clickLogin()
              }}>Login</div>
            </div>
          )
      }
    }
    setButton(<>{nikkei_btn()}{user_btn()}</>)
  }, [userInfo]);

  const clickMemberBtn = (() => {
    memberRef.current.classList.toggle('is-open')
    memberMenuRef.current.classList.toggle('is-open')
  });

  const clickLogin = (() => {
    const nonce = uuidv4();
    window.location.href = generateNikkeiIdMemberLoginUrl(pathname, queryString, nonce)
  });

  const clickRegistration = (async () => {
    await reqClearKbn();
    window.location.href = Consts.REACT.AUTH_IDRP_BASE_URL + '/reg/ai/frauth/start/'
  });

  const clickChange = (async () => {
    await reqClearKbn();
    window.location.href = Consts.REACT.AUTH_IDRP_BASE_URL + '/reg/ai/change/menu/'
  });

  const clickCloseMailmagazine = (async () => {
    await reqClearKbn();
    window.location.href = Consts.REACT.AUTH_IDRP_BASE_URL + '/reg/ai/close/confirm/'
  });

  const clickLogout = (async () => {
    await reqClear();
    window.location.href = generateNikkeiIdMemberLogoutUrl(userInfo.pathname)
  });

  return (
    <div className="header__right">
      {button}
    </div>
  );
}

export default HeaderRight;