import React, { useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import Consts from "../../../config/Consts";

export const ACCESS_LOG = gql`
  mutation (
    $token: ID,
    $article_id: ID,
    $channel_flag: Int,
    $folder_id: ID,
    $sub_folder_id: ID,
    $extend1: String,
    $extend2: String,
    $extend3: String,
    $display_time: String
  ) {
    access_log(
      token: $token,
      article_id: $article_id,
      channel_flag: $channel_flag,
      folder_id: $folder_id,
      sub_folder_id: $sub_folder_id,
      extend1: $extend1,
      extend2: $extend2,
      extend3: $extend3,
      display_time: $display_time
    ) {
      code
      result
    }
  }
`;

const AccessLog = ({
  post_client, article_detail
}) => {

  const article_id = article_detail.article_data.article_id
  const folder_id = article_detail.article_data.id_type === 2 ? article_detail.article_data.topic_id : article_detail.article_data.folder_id
  const sub_folder_id = article_detail.article_data.id_type === 2 ? article_detail.article_data.sub_topic_id : article_detail.article_data.sub_folder_id
  const [access_log] = useMutation(ACCESS_LOG, {client:post_client})

  useEffect(() => {
    if(article_id && folder_id && article_id.length <= 28 && folder_id.length <= 14) {
      var cookies = document.cookie.split('; ');
      var access_article_id = '';
      cookies.forEach(function (item) {
        if (item.indexOf('access_article_id') !== -1) {
          access_article_id = item.split('=')[1];
        }
      });
      if (access_article_id !== article_id) {
        document.cookie = 'access_article_id=' + article_id + ';secure';
        access_log({
          variables: {
            token: "AccessLog",
            article_id: article_id,
            channel_flag: article_detail.channel_data.is_channel ? 1 : 0,
            folder_id: folder_id,
            sub_folder_id: (sub_folder_id && sub_folder_id.length <= 14) ? sub_folder_id : '',
            extend1: article_detail.article_data.news_attribute_id,
            extend2: article_detail.article_data.operation_tags ? (
              (article_detail.article_data.operation_tags.indexOf(Consts.ID.OPERATION_TAG_TU) !== -1) ? Consts.ID.OPERATION_TAG_TU :
              (article_detail.article_data.operation_tags.indexOf(Consts.ID.OPERATION_TAG_RTU) !== -1) ? Consts.ID.OPERATION_TAG_RTU : '') : '',
            extend3: article_detail.article_data.service_category,
            display_time: article_detail.article_data.display_time_org
          }
        }).then((val) => {
          if(val.data.access_log && val.data.access_log.code === -1) {
            // has error
          }
        }).catch((e) => {
          // has exception
        })
      }
    }
  }, []);

  return <></>
};

export default AccessLog;