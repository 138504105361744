import React from "react";
import { useTheme } from "styled-components";

import SimpleA from '../../atoms/SimpleA';
import ArticleA from "../../atoms/ArticleA";

const Article1ColumnItem = ({
  article, itemIndex
}) => {
  const theme = useTheme();

  const tags = article.topics.map((topic, index) => {
    return (
      <li key={`tag-a1c-${theme.page}-${index}`} className="m-tag__item">{`#${topic.label}`}</li>
    );
  });

  // 飛び先リンクのセット
  const tmp_article = JSON.parse(JSON.stringify(article));
  if (article.link_url) {
    tmp_article.target_link = article.link_url;
  }

  return (
    <>
      <li className="m-article-list-1column__item">
        <ArticleA
          article={tmp_article}
          data-atlas-trackable={`top_new_lower_${itemIndex}`}
        >
          <div className="m-article-list-1column__image">
            <img src={article.image.src} alt="" />
          </div>
          <div className="m-article-list-1column__text">
            <p className="m-article-list-1column__category">{article.wappen}</p>
            <p className="m-article-list-1column__headings">{article.title}</p>
            <p className="m-article-list-1column__lead">{article.snippet}</p>
            <div className="m-tag">
              <ul className="m-tag__list">
                {tags}
              </ul>
            </div>
          </div>
        </ArticleA>
      </li>
    </>
  )
};

export default Article1ColumnItem;