import React from 'react';

const SimpleA = ({
  href,
  target,
  ga_category,
  ga_action,
  ga_label,
  children,
  onClick = () => { },
  ...props
}) => {
  return (
    <a
      href={href}
      target={target}
      {...(ga_category !== undefined && ga_action !== undefined && ga_label !== undefined) ?
        {
          onClick: (e) => {
            onClick(e);
          }
        } : { onClick: onClick }
      }
      {...props}
    >
      {children}
    </a>
  )
};

export default SimpleA;