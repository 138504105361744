import React, { useEffect, useCallback, useRef } from "react";
import PropTypes from 'prop-types';
import { useTheme } from "styled-components";
import useIsVisible from "../../../hooks/useIsVisible";

const AdTop = ({
  adid, device, stub_bottom
}) => {
  const theme = useTheme();
  const section = useRef();
  const adDiv = useRef();
  const container = useRef();
  const stub = useRef();
  const isVisible = useIsVisible(section, {once: true});

  useEffect(() => {
    if(isVisible) {
      loadAd();
      showAd();
    }
  }, [isVisible]);
  const loadAd = useCallback(() => {
    if (adid) {
      if (typeof TagProvider !== "undefined") {
        try {
          /*global TagProvider */
          TagProvider.requestAds({
            sectionId: adid.SECTION,
            dmp: true,
            sync: false,
            // handler: (a) => {
            //   /*global process_tag_provider_response_11516 */
            //   // process_tag_provider_response_11516(a);
            //   // TagProvider.displayAd(`${adid.SLOT}_${adid.SECTION}`);
            //   console.log('hander', a)
            //   // if(a.length < 0) {
            //   //   section.current.remove();
            //   // }
            //   return a;
            // }
          });
        } catch (err) {
        }

        try {
          TagProvider.displayAd(`${adid.SLOT}_${adid.SECTION}`);
        } catch (err) {
        }
      }
    }
  });
  const showAd = () => {
    const listener = () => {
      // レクタングルは iframe で表示されることが前提
      const ad = section.current.querySelector("iframe");
      // 高さが1以上ならsectionを表示
      if (ad) {
        //  ad.parentElement.style.displayが即座には取得できないので100ミリ秒後に確認
        setTimeout(() => {
          if (parseInt(ad.style.height) > 1 && ad.parentElement.style.display !=='none') {
            container.current.setAttribute('style', 'display:block');
            // m-ad-topを最初からいれていると一瞬paddingが表示されてしまうためここで追加
            section.current.classList.add("m-ad-top");
            if(stub_bottom) {
              stub.current.setAttribute('style', 'display:block');
            }
            removeListner();
          } else {
            if(section.current.getAttribute('style') !== 'display:none !important' ) {
              section.current.setAttribute('style', 'display:none !important');
            }
          }
        }, 100)
      }
    }
    // MutationObserverのインスタンスを作成
    let observer = new MutationObserver(function(mutationsList, observer) {
      for(let mutation of mutationsList) {
        if (mutation.type === 'childList' || mutation.type === 'subtree' || mutation.type === 'attributes') {
          listener();
        }
      }
    });
    const removeListner = () => {
      // 監視の停止 (必要に応じて)
      observer.disconnect();
    }
    // 監視オプション
    let config = { childList: true, subtree: true, attributes: true };
    // 監視を開始
    observer.observe(section.current, config);
  }

  if (adid.SLOT.length === 0) return null;
  return (
    <>
      <section className={`p-${theme.page}__ad-top is-${device}`} ref={section}
        data-testid={`block-name-${adid.SLOT}_${adid.SECTION}`}>
        {isVisible &&
          <div className="l-container" ref={container} style={{ display: 'none' }} >
            <div id={`${adid.SLOT}_${adid.SECTION}`} ref={adDiv} />
          </div>
        }
      </section>
      {stub_bottom && <div className="m-ad-top-stub_bottom" ref={stub} style={{ display: 'none' }} />}
    </>
  );
}

AdTop.propTypes = {
  adid: PropTypes.object.isRequired,
  device: PropTypes.string.isRequired
}

export default AdTop;