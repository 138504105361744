import React from "react";

import ArticleA from "../../atoms/ArticleA";

const RankingItem = ({
  article
}) => {
  return (
    <li className="m-ranking-contents-item">
      <ArticleA
        article={article}
        data-atlas-trackable='ranking'
      >
        <div className="m-ranking-contents-image">
          <img src={article.image.src} alt="" />
        </div>
        <div className="m-ranking-contents-text">
          <p className="m-ranking-contents-category">{article.wappen}</p>
          <p className="m-ranking-contents-headings">{article.title}</p>
        </div>
      </ArticleA>
    </li>
  );
}

export default RankingItem;