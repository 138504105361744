import React, { useContext } from "react";
import { gql, useQuery } from "@apollo/client";
import { useTheme } from "styled-components";

import PushListItem from '../../molecules/PushListItem';
import { QueryStringContext } from "../../../contexts/QueryStringContext";

export const PUSH_LIST_GET_ARTICLES = gql`
  query (
    $token: ID,
    $topic_id: ID,
    $offset:Int,
    $length: Int,
    $width: Int,
    $height: Int,
    $date_staging: String)
  {
    articles(
            token: $token,
            topic_id: $topic_id,
            offset: $offset,
            length: $length,
            date_staging: $date_staging) {
      article {
        title
        snippet
        description
        wappen
        subchannel_name
        target_link
        display_time
        sponsor
        is_new
        movie_flag
        link_type
        direct_move_flag
        square_image(width: $width, height: $height, crop:true) {
          src
          etoki
          credit
        }
        topics {
          topic_id
          label
        }
      }
    }
  }
`;

const PushList = ({
  topic_id
}) => {
  const theme = useTheme();
  const { queryString } = useContext(QueryStringContext);
  const date_staging = typeof queryString.k !== 'undefined' ? queryString.k : ''

  const variables = {
    "token": "PushList",
    "topic_id": topic_id,
    "offset": 0,
    "length": 3,
    "width": 624,
    "height": 624,
    "date_staging": date_staging
  };

  const { loading, error, data } = useQuery(PUSH_LIST_GET_ARTICLES, {
    variables: variables
  });
  if (loading) return null;
  if (error) return null;

  const pushlist_items = data.articles.article.map((article, index) => {
    const props = {
      article: article
    }
    return (
      <React.Fragment key={`pushlist-${index}`}>
        <PushListItem  {...props} />
      </React.Fragment>
    );
  });
  if(pushlist_items.length === 0) return null;

  return (
    <ul className={`p-${theme.page}__push-list m-article-list-3column`}
      data-testid="block-name-pushlist">
      {pushlist_items}
    </ul>
  );
}

export default PushList;