import React, { useRef } from "react";
import PropTypes from 'prop-types';
import RectangleAd from "../../ads/RectangleAd";

const Article3ColumnRectangleAd = ({
  adid,
  device
}) => {
  const adLi = useRef();
  const noad = () => {
    // 広告非表示の場合親のulのclassをnoneに
    if(device === 'pc' && adLi.current.parentElement) {
      adLi.current.parentElement.className
        = adLi.current.parentElement.className.replace("double", "none");
      adLi.current.parentElement.className
        = adLi.current.parentElement.className.replace("single", "none");
    }
    // PCの場合はliを削除
    // SPの場合は非表示 <-- CSSの関係でliの数を合わせる必要があるため
    if(device === 'pc') {
      adLi.current.remove();
    } else {
      adLi.current.style.setProperty("display", "none", "important");
    }
  }
  const double = () => {
    // PCの場合のみ親のulを変更
    if(device === 'pc') {
      adLi.current.parentElement.className
        = adLi.current.parentElement.className.replace("single", "double");
    }
    adLi.current.className
      = adLi.current.className.replace("single", "double");
    // div.p-index__ad-singleが必要な場合はいれる
    // adLi.current.children[0].className
    // div.p-index__ad-single__imageが必要な場合はいれる
    //   = adLi.current.children[0].className.replace("single", "double");
    // adLi.current.children[0].children[0].className
    //   = adLi.current.children[0].children[0].className.replace("single", "double");
  }
  return (
    <li className={`m-article-list-3column__item is-ad-single is-${device}`} ref={adLi}>
      {/* <div className="p-index__ad-single"> */}
        {/* <div className="p-index__ad-single__image"> */}
          <RectangleAd adid={adid} noad={noad} double={double} device={device} />
        {/* </div> */}
      {/* </div> */}
    </li>
  )
}

Article3ColumnRectangleAd.propTypes = {
  adid: PropTypes.object.isRequired,
  device: PropTypes.string.isRequired
}

export default Article3ColumnRectangleAd;