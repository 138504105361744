import Consts from '../../config/Consts';

/**
* 文字列の幅を返す。
*
* この関数はPHPのmb_strwidth互換です。
*
* @param   string  str 幅を測りたい文字列
* @return  int     文字列幅
*/
export const mb_strwidth = (str) => {
  var i = 0;
  var str_width = 0;
  var next_char_code = 0;
  var char_code = 0;

  //一文字ずつ処理
  while (!isNaN((char_code = str.charCodeAt(i++)))) {
    //サロゲートペア対応
    if (0xD800 <= char_code && char_code <= 0xDBFF) {
      next_char_code = str.charCodeAt(i);
      char_code = ((char_code - 0xD800) * 0x400) + (next_char_code - 0xDC00) + 0x10000;
      i++;
    }

    if (0x0 <= char_code && char_code <= 0x10FF) {
      str_width += 1;
    } else if (0x1100 <= char_code && char_code <= 0x115F) {
      str_width += 2;
    } else if (0x1160 <= char_code && char_code <= 0x11A2) {
      str_width += 1;
    } else if (0x11A3 <= char_code && char_code <= 0x11A7) {
      str_width += 2;
    } else if (0x11A8 <= char_code && char_code <= 0x11F9) {
      str_width += 1;
    } else if (0x11FA <= char_code && char_code <= 0x11FF) {
      str_width += 2;
    } else if (0x1200 <= char_code && char_code <= 0x2328) {
      str_width += 1;
    } else if (0x2329 <= char_code && char_code <= 0x232A) {
      str_width += 2;
    } else if (0x232B <= char_code && char_code <= 0x2E7F) {
      str_width += 1;
    } else if (0x2E80 <= char_code && char_code <= 0x2E99) {
      str_width += 2;
    } else if (0x2E9A <= char_code && char_code <= 0x2E9A) {
      str_width += 1;
    } else if (0x2E9B <= char_code && char_code <= 0x2EF3) {
      str_width += 2;
    } else if (0x2EF4 <= char_code && char_code <= 0x2EFF) {
      str_width += 1;
    } else if (0x2F00 <= char_code && char_code <= 0x2FD5) {
      str_width += 2;
    } else if (0x2FD6 <= char_code && char_code <= 0x2FEF) {
      str_width += 1;
    } else if (0x2FF0 <= char_code && char_code <= 0x2FFB) {
      str_width += 2;
    } else if (0x2FFC <= char_code && char_code <= 0x2FFF) {
      str_width += 1;
    } else if (0x3000 <= char_code && char_code <= 0x303E) {
      str_width += 2;
    } else if (0x303F <= char_code && char_code <= 0x3040) {
      str_width += 1;
    } else if (0x3041 <= char_code && char_code <= 0x3096) {
      str_width += 2;
    } else if (0x3097 <= char_code && char_code <= 0x3098) {
      str_width += 1;
    } else if (0x3099 <= char_code && char_code <= 0x30FF) {
      str_width += 2;
    } else if (0x3100 <= char_code && char_code <= 0x3104) {
      str_width += 1;
    } else if (0x3105 <= char_code && char_code <= 0x312D) {
      str_width += 2;
    } else if (0x312E <= char_code && char_code <= 0x3130) {
      str_width += 1;
    } else if (0x3131 <= char_code && char_code <= 0x318E) {
      str_width += 2;
    } else if (0x318F <= char_code && char_code <= 0x318F) {
      str_width += 1;
    } else if (0x3190 <= char_code && char_code <= 0x31BA) {
      str_width += 2;
    } else if (0x31BB <= char_code && char_code <= 0x31BF) {
      str_width += 1;
    } else if (0x31C0 <= char_code && char_code <= 0x31E3) {
      str_width += 2;
    } else if (0x31E4 <= char_code && char_code <= 0x31EF) {
      str_width += 1;
    } else if (0x31F0 <= char_code && char_code <= 0x321E) {
      str_width += 2;
    } else if (0x321F <= char_code && char_code <= 0x321F) {
      str_width += 1;
    } else if (0x3220 <= char_code && char_code <= 0x3247) {
      str_width += 2;
    } else if (0x3248 <= char_code && char_code <= 0x324F) {
      str_width += 1;
    } else if (0x3250 <= char_code && char_code <= 0x32FE) {
      str_width += 2;
    } else if (0x32FF <= char_code && char_code <= 0x32FF) {
      str_width += 1;
    } else if (0x3300 <= char_code && char_code <= 0x4DBF) {
      str_width += 2;
    } else if (0x4DC0 <= char_code && char_code <= 0x4DFF) {
      str_width += 1;
    } else if (0x4E00 <= char_code && char_code <= 0xA48C) {
      str_width += 2;
    } else if (0xA48D <= char_code && char_code <= 0xA48F) {
      str_width += 1;
    } else if (0xA490 <= char_code && char_code <= 0xA4C6) {
      str_width += 2;
    } else if (0xA4C7 <= char_code && char_code <= 0xA95F) {
      str_width += 1;
    } else if (0xA960 <= char_code && char_code <= 0xA97C) {
      str_width += 2;
    } else if (0xA97D <= char_code && char_code <= 0xABFF) {
      str_width += 1;
    } else if (0xAC00 <= char_code && char_code <= 0xD7A3) {
      str_width += 2;
    } else if (0xD7A4 <= char_code && char_code <= 0xD7AF) {
      str_width += 1;
    } else if (0xD7B0 <= char_code && char_code <= 0xD7C6) {
      str_width += 2;
    } else if (0xD7C7 <= char_code && char_code <= 0xD7CA) {
      str_width += 1;
    } else if (0xD7CB <= char_code && char_code <= 0xD7FB) {
      str_width += 2;
    } else if (0xD7FC <= char_code && char_code <= 0xF8FF) {
      str_width += 1;
    } else if (0xF900 <= char_code && char_code <= 0xFAFF) {
      str_width += 2;
    } else if (0xFB00 <= char_code && char_code <= 0xFE0F) {
      str_width += 1;
    } else if (0xFE10 <= char_code && char_code <= 0xFE19) {
      str_width += 2;
    } else if (0xFE1A <= char_code && char_code <= 0xFE2F) {
      str_width += 1;
    } else if (0xFE30 <= char_code && char_code <= 0xFE52) {
      str_width += 2;
    } else if (0xFE53 <= char_code && char_code <= 0xFE53) {
      str_width += 1;
    } else if (0xFE54 <= char_code && char_code <= 0xFE66) {
      str_width += 2;
    } else if (0xFE67 <= char_code && char_code <= 0xFE67) {
      str_width += 1;
    } else if (0xFE68 <= char_code && char_code <= 0xFE6B) {
      str_width += 2;
    } else if (0xFE6C <= char_code && char_code <= 0xFF00) {
      str_width += 1;
    } else if (0xFF01 <= char_code && char_code <= 0xFF60) {
      str_width += 2;
    } else if (0xFF61 <= char_code && char_code <= 0xFFDF) {
      str_width += 1;
    } else if (0xFFE0 <= char_code && char_code <= 0xFFE6) {
      str_width += 2;
    } else if (0xFFE7 <= char_code && char_code <= 0x1AFFF) {
      str_width += 1;
    } else if (0x1B000 <= char_code && char_code <= 0x1B001) {
      str_width += 2;
    } else if (0x1B002 <= char_code && char_code <= 0x1F1FF) {
      str_width += 1;
    } else if (0x1F200 <= char_code && char_code <= 0x1F202) {
      str_width += 2;
    } else if (0x1F203 <= char_code && char_code <= 0x1F20F) {
      str_width += 1;
    } else if (0x1F210 <= char_code && char_code <= 0x1F23A) {
      str_width += 2;
    } else if (0x1F23B <= char_code && char_code <= 0x1F23F) {
      str_width += 1;
    } else if (0x1F240 <= char_code && char_code <= 0x1F248) {
      str_width += 2;
    } else if (0x1F249 <= char_code && char_code <= 0x1F24F) {
      str_width += 1;
    } else if (0x1F250 <= char_code && char_code <= 0x1F251) {
      str_width += 2;
    } else if (0x1F252 <= char_code && char_code <= 0x1FFFF) {
      str_width += 1;
    } else if (0x20000 <= char_code && char_code <= 0x2FFFD) {
      str_width += 2;
    } else if (0x2FFFE <= char_code && char_code <= 0x2FFFF) {
      str_width += 1;
    } else if (0x30000 <= char_code && char_code <= 0x3FFFD) {
      str_width += 2;
    } else if (0x3FFFE <= char_code && char_code <= 0x10FFFF) {
      str_width += 1;
    }
  }

  return str_width;
};

/**
* Check and substr, add chars into end of input string
*
* @param String     str       string input
* @param Int        position  the postion use to check and sub
* @param String     addStr    string will join with string cut
* @param Int        snippet2_continue_flag  continue flag
* @return  string
*/
export const lm_substr = (str, position, addstr, snippet2_continue_flag) => {
  if (str === undefined || str === "") { return ""; }
  var result = str;
  addstr = (typeof addstr !== "undefined") ? addstr : '';
  snippet2_continue_flag = (typeof snippet2_continue_flag !== "undefined") ? snippet2_continue_flag : '';
  if (mb_strwidth(result) / 2 < position || position === 0) {
    if (snippet2_continue_flag === 1 && str.substr(-1, 1) !== "。") {
      return result + addstr;
    } else {
      return result;
    }
  }
  else if (mb_strwidth(result) / 2 === position) {
    if (snippet2_continue_flag !== 1) {
      return result;
    }
  }

  //Cut string
  for (var i = 1; i <= str.length; i++) {
    var ch = str.substring(0, i);
    var post_bye = (mb_strwidth(ch) + (addstr ? Consts.LIMIT.THREE_DOTS_LENGTH : 0)) / 2;
    if (post_bye === position) {
      if (ch.substr(-1, 1) !== "。") {
        if (str.substring(i, i + 1) === '。') {
          return ch + '。';
        } else {
          return ch + addstr;
        }
      } else {
        return ch;
      }
    }
    if (post_bye > position) {
      var rtn = str.substring(0, i - 1);
      if (rtn.substr(-1, 1) !== "。") {
        if (str.substring(i - 1, i) === '。') {
          return rtn + '。';
        } else {
          return rtn + addstr;
        }
      } else {
        return rtn;
      }
    }
  }
};

export const strncmp = (str1, str2, length) => {
  let s1 = (str1 + '').substr(0, length)
  let s2 = (str2 + '').substr(0, length)

  return ((s1 === s2) ? 0 : ((s1 > s2) ? 1 : -1))
};
