import React from "react";
import { useTheme } from "styled-components";

import ArticleA from "../../atoms/ArticleA";

const InformationItem = ({
  article
}) => {
  const theme = useTheme();

  const tags = article.topics.map((topic, index) => {
    return (
      <li key={`tag-i-${theme.page}-${index}`} className="m-tag__item">{`#${topic.label}`}</li>
    );
  });

  return (
    <li className="m-article-list-5column__item">
      <ArticleA
        article={article}
        data-atlas-trackable='top_information'
      >
        <div className="m-article-list-5column__image">
          <img src={article.image.src} alt="" />
        </div>
        <div className="m-article-list-5column__text">
          <p className="m-article-list-5column__headings">{article.title}</p>
          <div className="m-tag">
            <ul className="m-tag__list">
              {tags}
            </ul>
          </div>
        </div>
      </ArticleA>
    </li>
  )
};

export default InformationItem;