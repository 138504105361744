import moment from 'moment-timezone';
import axios from 'axios';
import queryString from 'query-string';
import { isAxiosError } from 'axios';

import Consts from '../../config/Consts';

/**
 * 認証システムからの呼び出し
 * クエリにerrorおよびcodeが含まれる場合はページ遷移
 * 
 * @param {*} req 
 * @param {*} res 
 * @param {*} pathname 
 * @param {*} qs 
 */
export const chkAuthQuery = async (req, res, pathname = '') => {
  res.set('X-Robots-Tag', 'noindex, nofollow');
  if(req && req.query) {
    const qs = req.query;
    let path = pathname
    // 認証システムからのクエリを想定
    if (qs.error) {
      // エラーコードが返されたのでセッションをクリア
      clearSession(req, res);
    } else if (qs.code) {
      // 認証コード取得後
      // state：パス情報
      // code：認可コード
      // iss：認可サーバ
      res.setHeader('Cache-Control', 'no-store');
      const code = qs.code
      delete qs.code
      if (qs.state) {
        path = path.replace(/\/$/, "") + decodeURIComponent(qs.state);
        delete qs.state;
      }
      if (qs.iss) {
        delete qs.iss;
      }
      // 返却されたcodeの正当性確認
      if(!await identify( req, res, code, getRedirectState(path.replace(Consts.COMMON.PASS_PATH, ''), qs) )) {
        // 正しい情報ではないのでセッションをクリアしクッキーも削除
        sessionDestroy(req, res);
      }
    }
    res.redirect(getRedirectUri(path.replace(Consts.COMMON.PASS_PATH, ''), qs));
  } else {
    res.redirect(getRedirectUri(pathname.replace(Consts.COMMON.PASS_PATH, '')));
  }
};

/**
 * セッション情報のクリア
 * @param {*} req 
 * @param {*} res 
 * @param {*} redirect 
 */
const clearSession = (req, res, redirect=null) => {
  // セッションを削除
  sessionDestroy(req, res);
  // クッキーの削除
  res.cookie(Consts.REACT.NID_AUTHENTICATED, '', {maxAge:1, domain:'.nikkei.com'});
  // リダイレクト指定がある場合はリダイレクトする
  if(redirect) {
    res.redirect(redirect)
  }
}

/**
 * 
 * @param {*} req 
 * @param {*} res 
 */
const sessionDestroy = (req, res) => {
  if(req.session) {
    // セッションを削除
    req.session.destroy((err) => {
      // if (err) {
      //   res.send(err)
      //   return
      // }
      // res.redirect('/')
    })
  }
}

/**
 * 
 * @param {*} req 
 * @param {*} res 
 * @param {*} code 
 * @param {*} pathname 
 * @returns 
 */
const identify = async (req, res, code, pathname) => {
  if(!req.session) {
    return false;
  }

  const token = await reqAccessToken(code);
  if(!token) return false;

  const user = await reqUserInfo(token);
  if(!user) return false;

  return await __identify( req, res, user.USER_PPID, user.SEX, pathname );
}

/**
 * トークンの取得
 * 
 * @param {*} code コード
 * @returns トークン
 */
const reqAccessToken = async (code) => {
  const url = Consts.REACT.AUTH_NID_BASE_URL + '/v2/connect/token';
  const headers = {
    Authorization: `Basic ${new Buffer.from(`${Consts.REACT.AUTH_CLIENT_ID}:${Consts.REACT.AUTH_CLIENT_SECRET}`).toString('base64')}`
  }
  const parameters = {
    grant_type: 'authorization_code',                   // 固定値「authorization_code」
    redirect_uri: getRedirectUriBase(true),             // 認可エンドポイントにリクエストしたリダイレクトURL
    code: code,                                         // 認可エンドポイントで発行された認可コード
    client_id: Consts.REACT.AUTH_CLIENT_ID,             // Web.Configから取得した利用サイトID
  };
  return await callApi(url, parameters, headers).then((value) => {
    if(value && value.statusText === 'OK' && value.data.access_token) {
      // access_token: アクセストークン
      // refresh_token: アクセストークンを更新するために利用されるリフレッシュトークン
      // scope: アクセストークンに紐付くスコープ'openid'
      // id_token: IDトークン
      // token_type: 'Bearer'
      // expires_in: アクセストークンの有効期限が、UnixEpochからの経過秒数で設定される（3600）
      return value.data.access_token;
    }
    return null;
  }).catch((error) => {
    /* TODO */if(isAxiosError(error) && error.response) {
    /* TODO */  console.log("reqAccessToken",error.response.status);
    /* TODO */  console.log("reqAccessToken",error.response.statusText);
    /* TODO */  console.log("reqAccessToken",error.response.data);
    /* TODO */} else {
    /* TODO */  console.log("reqAccessToken",error);
    /* TODO */}
    // Logger.system.error(error.stack ? error.stack : error.message);
    return null;
  });
}

const reqUserInfo = async (token) => {
  const url = Consts.REACT.AUTH_NID_BASE_URL + '/v2/resource/userinfo/2.0';
  const headers = {
    Authorization: `Bearer ${token}`
  }
  const parameters = {
  };
  return await callApi(url, parameters, headers).then((value) => {
    if(value && value.statusText === 'OK' && value.data.USER_PPID) {
      // USER_PPID        ○ オープン化ユーザID
      // EMAIL
      // ENTRY_DATE
      // LAST_NAME        ○ 日経ID会員の名
      // FIRST_NAME	      ○ 日経ID会員の姓
      // LAST_NAME_KANA
      // FIRST_NAME_KANA
      // RESIDENCE_COUNTRY_FLAG
      // ZIP_CODE
      // SEX
      // BIRTH
      // NIKKEI_MEMBER_NO ○ 日経ID会員番号
      // USER_SERIAL_ID
      // AUTOLOGIN_EXP_FLG
      return value.data
    }
    return null;
  }).catch((error) => {
    /* TODO */if(isAxiosError(error) && error.response) {
    /* TODO */  console.log("reqUserInfo",error.response.status);
    /* TODO */  console.log("reqUserInfo",error.response.statusText);
    /* TODO */  console.log("reqUserInfo",error.response.data);
    /* TODO */} else {
    /* TODO */  console.log("reqUserInfo",error);
    /* TODO */}
    // Logger.system.error(error.stack ? error.stack : error.message);
    return null;
  });
}

/**
 * ユーザ・区分をセッションに載せる
 * 
 * @param {*} ppid 
 * @param {*} sex 
 * @param {*} pathname 
 * @returns 
 */
const __identify = async (req, res, ppid, sex, pathname) => {
  if(!req.session) {
    return false;
  }
  if(!ppid) return false;

  const date = moment.tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm:ss');
  const kbn = await reqMemberInfo(ppid);
  if(!kbn) return false;
  req.session.user_id = ppid;
  req.session.sex = sex;
  req.session.site_user_kbn = kbn;
  req.session.pathname = pathname;
  req.session.login_date = date;
  req.session.access_date = date;
  return true;
}

export const reqMemberInfo = async (ppid) => {
  const url = Consts.REACT.AUTH_IDRP_BASE_URL + '/reg/ai/api/RpMember.svc/GetMemberInfo';
  const parameters = {
    site_id: Consts.REACT.AUTH_SITE_ID,     // Web.Configから取得したサービスサイトID
    site_pass: Consts.REACT.AUTH_SITE_PASS, // Web.Configから取得したサービスサイトIDのパスワード
    user_ppid: ppid                         // オープン化ユーザID
  };
  return await callApi(url, parameters).then((value) => {
    if(value && value.statusText === 'OK') {
      if(value.data['RESULT-CODE']) {
        // RESULT-CODE	0000:会員、
        //              0010:非会員
        // USER_NO_KP	  日経BizアカデミーサイトID
        return value.data['RESULT-CODE']
      } else {
        return '9999'
      }
    }
    return null;
  }).catch((error) => {
    /* TODO */if(isAxiosError(error) && error.response) {
    /* TODO */  console.log("reqMemberInfo",error.response);
    /* TODO */} else {
    /* TODO */  console.log("reqMemberInfo",error);
    /* TODO */}
    // Logger.system.error(error.stack ? error.stack : error.message);
    return null;
  });
}

/**
 * CSRからリクエスト
 * @returns 
 */
export const reqServerInfo = async () => {
  const url = Consts.COMMON.BASE_URL + Consts.COMMON.PASS_PATH + '/user';
  const parameters = {
  };
  return await callApi(url, parameters).then((value) => {
    if(value && value.status === 200) {
      return value.data
    }
    return null;
  }).catch((error) => {
    console.log(error);
    return null;
  });
}

/**
 * CSRからリクエスト
 * @returns 
 */
export const reqClearKbn = async () => {
  const url = Consts.COMMON.BASE_URL + Consts.COMMON.PASS_PATH + '/clear_kbn';
  const parameters = {
  };
  return await callApi(url, parameters).then((value) => {
    if(value && value.status === 200) {
      return value.data
    }
    return null;
  }).catch((error) => {
    console.log(error);
    return null;
  });
}

/**
 * CSRからリクエスト
 * @returns 
 */
export const reqClear = async () => {
  const url = Consts.COMMON.BASE_URL + Consts.COMMON.PASS_PATH + '/clear';
  const parameters = {
  };
  return await callApi(url, parameters).then((value) => {
    if(value && value.status === 200) {
      return value.data
    }
    return null;
  }).catch((error) => {
    console.log(error);
    return null;
  });
}

/**
 * 外部サーバへのPOST処理
 * @param {*} url 
 * @param {*} parameters 
 * @returns 
 */
const callApi = async (url, parameters, headers=null) => {
  const options = {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
  }
  if(headers) {
    for(const key in headers) {
      options.headers[key] = headers[key];
    }
  }
  return await axios.post(url, parameters, options);
}

/**
 * 日経ID会員の認可要求URLを生成して返却する。
 * 認証・認可後のリダイレクト先は現在リクエストされているページとする。
 * ※検索ページからのログインではTopページに戻す
 * 
 * @param {*} pathname 
 * @param {*} qs 
 * @return string 日経ID会員の認可要求URL
 */
export const generateNikkeiIdMemberLoginUrl = (pathname, qs, nonce) => {
  if(pathname === '/search') {
    pathname = '/'
  }
  const params = {
    response_type: 'code',                      // 固定値「code」
    redirect_uri: getRedirectUriBase(true),     // redirect_uriとして登録した認証用のURL（https://～magazine.nikkei.com/api/）
    scope: Consts.REACT.AUTH_SCOPE,             // 固定値「openid profile_nid」
    client_id: Consts.REACT.AUTH_CLIENT_ID,     // Web.Configから取得した利用サイトID
    nonce: nonce,                               // グローバル一意識別子（GUID）
    state: encodeURIComponent(getRedirectState(pathname, qs)) // redirect_uriに指定できないパス情報をこちらで連携する
  }
  const urlSearchParam =  new URLSearchParams(params).toString();
  return Consts.REACT.AUTH_NID_BASE_URL + '/v2/connect/authorization?' + urlSearchParam;
};

/**
 * 日経ID会員のシングルサインアウト要求URLを生成して返却する。
 * サインアウト後のリダイレクト先は総合トップページ固定とする。
 * 
 * @return string 日経ID会員のシングルサインアウトURL
 */
export const generateNikkeiIdMemberLogoutUrl = (pathname) => {
  const params = {
    client_id: Consts.REACT.AUTH_CLIENT_ID,   // Web.Configから取得したサービスサイトID
    post_logout_redirect_uri: getHomeUri()    // ログアウト後のリダイレクト先
  }
  const urlSearchParam =  new URLSearchParams(params).toString();
  return Consts.REACT.AUTH_NID_BASE_URL + '/v2/connect/logout?' + urlSearchParam;
};

/**
 * 
 * @returns 
 */
const getHomeUri = () => {
  return Consts.COMMON.BASE_URL + '/';
}

/**
 * リダイレクト用URLを生成する。
 * 
 * @param {*} pathname 
 * @param {*} qs 
 * @returns 
 */
export const getRedirectUri = (pathname, qs, withApi = false) => {
  if(withApi) {
    return Consts.COMMON.BASE_URL + Consts.COMMON.PASS_PATH + getRedirectState(pathname, qs);
  } else {
    return Consts.COMMON.BASE_URL + getRedirectState(pathname, qs);
  }
}

export const getRedirectUriBase = (withApi = false) => {
  if(withApi) {
    return Consts.COMMON.BASE_URL + Consts.COMMON.PASS_PATH + '/';
  } else {
    return Consts.COMMON.BASE_URL + '/';
  }
}

export const getRedirectState = (pathname, qs) => {
  if(typeof qs.error !== 'undefined') {
    // 日経IDシステムへの認可要求に対してエラー応答が返却された場合
    // 認可コードを除去した上で認証が要求された画面へ戻す
    delete qs.error
  }
  if(typeof qs.code !== 'undefined') {
    // 日経IDシステムへの認可要求に対して認可要求応答が返却された場合
    // エラーコードを除去した上で認証が要求された画面へ戻す
    delete qs.code
  }
  if(typeof qs.state !== 'undefined') {
    delete qs.state
  }
  if(typeof qs.iss !== 'undefined') {
    delete qs.iss
  }
  let urlSearchParam = '';
  if(qs && qs instanceof Object && !(qs instanceof Array) && Object.keys(qs).length > 0) {
    urlSearchParam = '?' + queryString.stringify(qs);
  }

  return pathname + urlSearchParam;
}
