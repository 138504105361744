import React, { useRef, useEffect } from "react";

import useScrollPosition from "../../../hooks/useScrollPosition";

const PageTopLink = () => {
  const pagetopRef = useRef();
  const scrollPosition = useScrollPosition();

  useEffect(() => {
    //50px以上スクロールしたらpagetopボタン表示、それ以外は非表示
    if (scrollPosition > 50) {
      pagetopRef.current.classList.add('is-show'); //pagetopボタン表示
    } else {
      pagetopRef.current.classList.remove('is-show'); //pagetopボタン非表示
    }
  }, [scrollPosition]);

  const easeInOut = (t) => {
    return t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1;
  }
  const clickTop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    var targetPosition = 0;
    var currentPostion = document.documentElement.scrollTop || document.body.scrollTop;
    var startTime = performance.now();
    var loop = function (nowTime) {
      var time = nowTime - startTime;
      var normalizedTime = time / 500;
      if (normalizedTime < 1) {
        window.scrollTo(0, currentPostion + ((targetPosition - currentPostion) * easeInOut(normalizedTime)));
        requestAnimationFrame(loop);
      } else {
        window.scrollTo(0, targetPosition);
      }
    }
    requestAnimationFrame(loop);
  }

  return (
    <div onClick={(e)=> {clickTop(e)}} id="pagetop" className="pagetop" ref={pagetopRef}></div>
  )
};

export default PageTopLink;