import React from "react";
import Consts from "../../../config/Consts";
import SimpleA from "../../atoms/SimpleA";

const NikkeiAiFooter = () => {
  return (
    <div id="nikkei-copyright" className="brand_white nikkei-ai">
      <div className="nikkei-copyright_inner">
        <p>
          <SimpleA className="nikkei-copyright_logo" href={Consts.LINK.NIKKEI_URL} target="_blank" rel="noopener" aria-label="nikkei">
            <img src="/images/common/logo-nikkei_white.svg" alt="NIKKEI" />
          </SimpleA>
          <SimpleA className="nikkei-copyright_kodansha" href="https://www.kodansha.co.jp/" target="_blank" rel="noopener" aria-label="kodansha">
            <img src="/images/common/logo-kodansha@2x.png" alt="" />
          </SimpleA>
          <span className="nikkei-copyright_text">No reproduction without permission.</span><span className="nikkei-copyright_ai">THE NIKKEI MAGAZINE Aiは日本経済新聞社と講談社が共同運営しています</span>
        </p>
        <p className="nikkei_copyright_ai-contact">編集：講談社　内容に関するお問い合わせは<SimpleA href={Consts.LINK.ABOUT_AI_ANSWERS_URL} target="_blank" rel="noopener">こちら &gt;</SimpleA></p>
      </div>
    </div>
  )
};

export default NikkeiAiFooter;