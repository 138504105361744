import React from "react";
import { useTheme } from "styled-components";

import ArticleA from "../../atoms/ArticleA";

const SliderItem = ({
  article
}) => {
  const theme = useTheme();

  const tags = article.topics.map((topic, index) => {
    return (
      <li key={`tag-s-${theme.page}-${index}`} className="m-tag__item">{`#${topic.label}`}</li>
    );
  });

  return (
    <div className={`p-${theme.page}__slider-item swiper-slide`}>
      <ArticleA
        article={article}
        data-atlas-trackable='top_carousel'
      >
        <div className={`p-${theme.page}__slider-text`}>
          <p className={`p-${theme.page}__slider-category`}>{article.wappen}</p>
          <h3 className={`p-${theme.page}__slider-headings`}>{article.title}</h3>
          <p className={`p-${theme.page}__slider-lead`}>{article.snippet}</p>
          {tags.length > 0 &&
            <div className="m-tag">
              <ul className="m-tag__list">
                {tags}
              </ul>
            </div>
          }
        </div>
        <div className={`p-${theme.page}__slider-image`}>
          <picture>
            <source media="(max-width: 767px)" srcSet={article.square_image.src} />
            <img src={article.image.src} alt="" />
          </picture>
        </div>
      </ArticleA>
    </div>
  );
}

export default SliderItem;