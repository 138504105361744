import React from "react";
import { useTheme } from "styled-components";
import RelatedItem from "../../molecules/RelatedItem";

const RelatedBlock = ({
  related_list,
  with_empty_padding = false,
}) => {
  const theme = useTheme();

  const related_items = related_list.map((article, index) => {
    const props = {
      article: article
    }
    return (
      <React.Fragment key={`ar-${index}`}>
        <RelatedItem {...props} />
      </React.Fragment>
    );
  });
  if(related_items.length === 0) {
    if(with_empty_padding) {
      return(
        <>
          <div className={`p-${theme.page}__related_e`}></div>
        </>
      );
    }
    return null;
  }

  return (
    <>
      <div className={`p-${theme.page}__related`}
        data-testid="block-name-related">
        <h3 className="m-section-headings">RELATED</h3>
        <ul className={`p-${theme.page}__related-list`}>
          {related_items}
        </ul>
      </div>
    </>
  )
};

export default RelatedBlock;