import React, { useContext, useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { useTheme } from "styled-components";

import SimpleA from '../../atoms/SimpleA';
import Consts from "../../../config/Consts";
import { generateNikkeiIdMemberLogoutUrl, reqClear } from '../../../utils/Authentication';
import { UserContext } from "../../../contexts/UserContext";
import { QueryStringContext } from "../../../contexts/QueryStringContext";
import ShareBlock from "../ShareBlock";

export const GET_CHANNEL_LIST = gql`
  query (
    $token: ID,
    $topics_topic_id: ID,
    $topics_information: Boolean,
    $topics_offset: Int,
    $topics_length: Int,
    $topics_width: Int,
    $topics_height: Int,
    $topics_date_staging: String
  ){
    category_list(
      token: $token
    ){
      label
      topic_id
      category_path
    }
    articles(
      token: $token,
      topic_id:$topics_topic_id,
      information: $topics_information,
      offset: $topics_offset,
      length: $topics_length,
      date_staging: $topics_date_staging
    ){
      article {
        target_link
        title
        link_type
        image(width: $topics_width, height: $topics_height, crop:true) {
          src
          etoki
          credit
        }
      }
    }
    tag_list {
      topic_id,
      label
    }
  }
`

const Menu = ({
  menuRef,
  menuCloseRef,
  clickMenuCloseBtn
}) => {
  const theme = useTheme();
  const { queryString } = useContext(QueryStringContext);
  const date_staging = typeof queryString.k !== 'undefined' ? queryString.k : ''
  const { userInfo } = useContext(UserContext);
  const [nikkei_link, setNikkeiLink] = useState(null);

  useEffect(() => {
    const nikkei_btn = () => {
      if(userInfo.status === 1) {
        return (
          <ul className="m-nikkei-list">
            <li className="m-nikkei-item">
              <SimpleA href={Consts.LINK.NIKKEI_URL} className="menu__btn-nikkei" target="_blank" rel="noopener">日経電子版TOP</SimpleA>
            </li>
            <li className="m-nikkei-item">
              <SimpleA className="menu__btn-nikkei-logout" onClick={() => { clickLogout() }}>ログアウト</SimpleA>
            </li>
          </ul>
        )
      } else {
        return (
          <SimpleA href={Consts.LINK.NIKKEI_URL} className="menu__btn-nikkei" target="_blank" rel="noopener">日経電子版TOP</SimpleA>
        )
      }
    }
    setNikkeiLink(nikkei_btn())
  }, [userInfo]);

  const clickLogout = (async () => {
    await reqClear();
    window.location.href = generateNikkeiIdMemberLogoutUrl(userInfo.pathname)
  });

  const { loading, error, data } = useQuery(GET_CHANNEL_LIST, {
    variables: {
      "token": "Nav",
      "topics_topic_id": Consts.ID.TOPICS,
      "topics_information": true,
      "topics_offset": 0,
      "topics_length": 3,
      "topics_width": 624,
      "topics_height": 234,
      "topics_date_staging": date_staging
    }
  });
  if (loading) return null;
  if (error) return null;

  const category_items = data.category_list ? data.category_list.map((category, index) => {
    return (
      <React.Fragment key={`menu-${index}`}>
        <li className="menu__item">
          <SimpleA href={`/${category.category_path}`} data-atlas-trackable={`menu_${category.label.toLowerCase().replace(/\s+/g, "")}`}>{category.label}</SimpleA>
        </li>
      </React.Fragment>
    );
  }) : null;

  const article_items = (data.articles && data.articles.article) ? data.articles.article.map((article, index) => {
    return (
      <React.Fragment key={`menu-topics-${index}`}>
        <li className="menu__topics-item">
          <SimpleA href={article.target_link} data-atlas-trackable="menu_topics">
            <div className="menu__topics-image">
              <img src={article.image.src} alt="" />
            </div>
            <div className="menu__topics-text">
              <p className="menu__topics-headings">{article.title}</p>
            </div>
          </SimpleA>
        </li>
      </React.Fragment>
    );
  }) : null;

  const tags = data.tag_list.map((tag, index) => {
    if(index < Consts.LIMIT.TAG_COUNT) {
      return (
        <li key={`menu-tag-${theme.page}-${index}`} className="m-tag__item"><SimpleA href={`/tag/${tag.topic_id}`} data-atlas-trackable="menu_tag">{`#${tag.label}`}</SimpleA></li>
      );
    } else {
      return null
    }
  });

  return (
    <>
      {/* TMNメニュー */}
      <div id="menu" className="menu" ref={menuRef}>
        <div className="menu__inner">
          <div className="menu__headings">
            <div id="menu__close" className="menu__close btn-menu" ref={menuCloseRef} onClick={() => {
              clickMenuCloseBtn()
            }}></div>
            <div className="menu__logo">
              <img src="/images/common/menu_logo@2x.png" alt="THE NIKKEI MAGAZINE" />
            </div>
          </div>
          <div className="menu__search">
            <form action="/search">
              <input type="text" name="keyword" placeholder="" maxLength="100" />
              <button className="menu__search-btn" type="submit" />
            </form>
          </div>
          <ul className="menu__list">
            {category_items}
          </ul>
          <div className="menu__topics">
            <p className="menu__section-headings">TOPICS</p>
            <ul className="menu__topics-list">
              {article_items}
            </ul>
          </div>
          <div className="menu__tag">
            <p className="menu__section-headings">TAG</p>
            <SimpleA href="/taglist" className="menu__tag-btn-view-all">VIEW ALL &gt;</SimpleA>
            <ul className="m-tag__list">
              {tags}
            </ul>
          </div>
          <div className="menu__share">
            <ShareBlock />
          </div>
          <div className="menu__btn is-sp">
            {nikkei_link}
          </div>
        </div>
      </div>
      {/* /TMNメニュー */}
    </>
  );
}

export default Menu;