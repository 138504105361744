import React from "react";
import { useTheme } from "styled-components";

import SimpleA from '../../atoms/SimpleA';

const RelatedItem = ({article}) => {
  const theme = useTheme();

  return (
    <li className={`p-${theme.page}__related-item`}>
      <SimpleA
        href={article.link_url}
        data-atlas-trackable="article_related"
      >
        <div className={`p-${theme.page}__related-image`}>
          <img src={article.image_url} alt={article.image_etoki} />
        </div>
        <div className={`p-${theme.page}__related-column`}>
          <p className={`p-${theme.page}__related-headings`}>{article.sub_title}</p>
        </div>
      </SimpleA>
    </li>
  )
};

export default RelatedItem;