import React from "react";
import { useTheme } from "styled-components";

import ArticleA from "../../atoms/ArticleA";

const Article3ColumnItem = ({
  article, itemIndex
}) => {
  const theme = useTheme();

  const tags = article.topics.map((topic, index) => {
    return (
      <li key={`tag-a3c-${theme.page}-${index}`} className="m-tag__item">{`#${topic.label}`}</li>
    );
  });

  // 飛び先リンクのセット
  const tmp_article = JSON.parse(JSON.stringify(article));
  if (article.link_url) {
    tmp_article.target_link = article.link_url;
  }

  return (
    <>
      <li className={article.is_new ? "m-article-list-3column__item is-new" : "m-article-list-3column__item" }>
        <ArticleA
          article={tmp_article}
          data-atlas-trackable={`top_new_upper_${itemIndex}`}
        >
          <div className="m-article-list-3column__image">
            <img src={article.image.src} alt="" />
          </div>
          <div className="m-article-list-3column__text">
            <p className="m-article-list-3column__category">{article.wappen}</p>
            <h3 className="m-article-list-3column__headings">{article.title}</h3>
            <div className="m-tag">
              <ul className="m-tag__list">
                {tags}
              </ul>
            </div>
          </div>
        </ArticleA>
      </li>
    </>
  );
};

export default Article3ColumnItem;