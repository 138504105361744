import React from "react";
import { ThemeProvider } from "styled-components";
import Header from "../../molecules/Header";
import Nav from "../../molecules/Nav";
import TaglistBlock from "../../organisms/TaglistBlock";
import MainTemplate from "../../templates/MainTemplate";
import addslashes from "../../../utils/AddSlashes";
import Consts from "../../../config/Consts";

const TaglistPage = ({post_client}) => {

  const label = "タグ一覧"
  const title = label + '｜THE NIKKEI MAGAZINE｜日本経済新聞社'
  const description = Consts.COMMON.DESCRIPTION
  const analyticsVars = 'var analyticsVars = {title:"' + addslashes(title) + '"};';
  const helmet = [
    <title key='h-title'>{title}</title>,
    <meta key='h-meta-1' name="description" content={description} />,
    <meta key='h-meta-2' property="og:title" content={title} />,
    <meta key='h-meta-3' property="og:description" content={description} />,
    <link key='h-canonical' rel="canonical" href={`${Consts.COMMON.BASE_URL}/taglist`} />,
    <script key='h-script-1' type="text/javascript">{analyticsVars}</script>
  ]

  const mode = 'default';
  const page = 'taglist';
  const adpagetype = 'ARTICLE';

  return (
    <ThemeProvider theme={{ mode, page, adpagetype }}>
      <MainTemplate
        helmet={helmet}
        header={
          <Header isTop={true} post_client={post_client} />
        }
        main={
          <>
            <Nav />
            <TaglistBlock label={label} />
          </>
        }
      />

    </ThemeProvider>
  )
};

export default TaglistPage;