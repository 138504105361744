import React, { useContext, useEffect, useRef } from "react";
import { useTheme } from "styled-components";

import TuTemplate from "../../templates/TuTemplate";
import NikkeiFooter from "../../molecules/NikkeiFooter";
import NikkeiHeader from "../../molecules/NikkeiHeader";
import AccessLog from "../../molecules/AccessLog";
import ScriptTag from "../../atoms/ScriptTag";

import htmlEntities from '../../../utils/HtmlEntities';
import FooterBlock from "../FooterBlock";
import Header from "../../molecules/Header";
import { UserContext } from "../../../contexts/UserContext";

const ContentsPr = ({
  article_detail,
  post_client,
  helmet,
  custom_key_topics
}) => {
  const theme = useTheme();
  const contentsRef = useRef();
  const { userInfo } = useContext(UserContext);

  useEffect(() => {
    contentsRef.current.classList.remove('u-member');
    contentsRef.current.classList.remove('u-nonmember');
    if(userInfo.status === 2) {
      contentsRef.current.classList.add('u-member');
    } else {
      contentsRef.current.classList.add('u-nonmember');
    }
  });

  const main_contents = () => {
    const srcs = [];
    let html = article_detail.article_data.tieup_article_html
    html = html.replace(/<script[\s\S]*?<\/script>/g, function (match) {
      var src = match.match(/src="(.+?)"/);
      if (src) {
        srcs.push(src[1]);
      }
      return '';
    });
    const script_tags = Array.from(new Set(srcs)).map((script, index) => {
      return (<ScriptTag src={script} key={`psc-${index}`} sid={`psct-${index}`} />)
    });
    // 大カテゴリーのリンクセット
    html = html.replace(/<p class="m-contents-top__category">[\s\S]*?<\/p>/g, function (match) {
      const text = match.replace(/<p class="m-contents-top__category">|<\/p>/g, '')
      if (!article_detail.article_data.category_path) {
        return `<p class="m-contents-top__category">${text}</p>`;
      }
      return ( `
        <p class="m-contents-top__category">
          <a href=${article_detail.article_data.category_path}>${text}</a>
        </p>
      `);
    });
    // 中カテゴリーのリンクセット
    html = html.replace(/<h3 class="m-contents-top__headings-h2">[\s\S]*?<\/h3>/g, function (match) {
      const text = match.replace(/<h3 class="m-contents-top__headings-h2">|<\/h3>/g, '')
      const { category_path, sub_category_path } = article_detail.article_data
      if (category_path && sub_category_path) {
        return ( `
          <h2 class="m-contents-top__headings-h2">
            <a href=${category_path}/${sub_category_path}>${text}</a>
          </h2>
        `);
      }
      return `<h2 class="m-contents-top__headings-h2">${text}</h2>`;
    });
    // 要素の書き換え
    html = html.replace(/<h2 class="m-contents-top__headings-h3">[\s\S]*?<\/h2>/g, function (match) {
      const text = match.replace(/<h2 class="m-contents-top__headings-h3">|<\/h2>/g, '')
      return `<h3 class="m-contents-top__headings-h3">${text}</h3>`;
    });
    return (
      <>
        <div className={`p-${theme.page} u-nonmember`} dangerouslySetInnerHTML={
          { __html: htmlEntities(html) }
        } />
        {script_tags}
      </>
    )
  }

  const header = article_detail.article_data.tieup_header_html.map((line, index) => {
    if(line.indexOf('<link') !== -1) {
      const regexp = /(\s((?:rel|type|media|href|charset))=(('([^'>]*)')|("([^">]*)")|([^\s>]*)))/ig
      let result
      const params = {rel:null, type:null, media:null, href:null, charset:null}
      while(result = regexp.exec(line)){
        const val = result[5]?result[5]:result[7]
        switch(result[2]) {
          case 'rel':
            params.rel = val
            break;
          case 'type':
            params.type = val
            break;
          case 'media':
            params.media = val
            break;
          case 'href':
            params.href = val
            break;
          case 'charset':
            params.charset = val
            break;
          default:
            break;
        }
      }
      if(params.rel !== "stylesheet" || !params.href) return null;
      return <link key={`hl-${index}`}
        {...params.rel && {rel:params.rel}}
        {...params.type && {type:params.type}}
        {...params.media && {media:params.media}}
        {...params.href && {href:params.href}}
        {...params.charset && {charset:params.charset}}
      ></link>
    } else if(line.indexOf('<script') !== -1) {
      const regexp = /(\s((?:type|src))=(('([^'>]*)')|("([^">]*)")|([^\s>]*)))|<script>[^>]+>/ig
      let result
      const params = {type:null, src:null, script: null}
      while(result = regexp.exec(line)){
        const val = result[5]?result[5]:result[7]
        switch(result[2]) {
          case 'type':
            params.type = val
            break;
          case 'src':
            params.src = val
            break;
          default:
            const script = result[0];
            if (script && script.startsWith('<script>') && script.endsWith('</script>')) {
              params.script = result[0]
            }
            break;
        }
      }
      if (params.script) {
        params.script = params.script.replace(/<script>/, '')
        params.script = params.script.replace(/<\/script>/, '')
        return <script key={`hs-${index}`}>{params.script}</script>
      }
      if(!params.src) return null;
      return <script key={`hs-${index}`}
        {...params.type && {type:params.type}}
        {...params.src && {src:params.src}}
      ></script>
    }
    return null;
  })

  const custom_key_param = { topics: custom_key_topics, article_id: article_detail.article_data.article_id }
  return (
    <TuTemplate
      custom_key_param={custom_key_param}
      helmet={
        <>
          {helmet}
          {header}
        </>
      }
      main={
        <>
          <NikkeiHeader pr={true} />
          <div className={`p-${theme.page}`} ref={contentsRef}>
            <Header post_client={post_client} />
            {main_contents()}
            <AccessLog post_client={post_client} article_detail={article_detail} />
            <FooterBlock pr={true} />
            <NikkeiFooter />
          </div>
        </>
      }
    />
  )
};

export default ContentsPr;